import { FaStar } from "react-icons/fa6";
import { FaArrowCircleRight } from "react-icons/fa";
import React, { useState } from 'react';
import FAQ from "../../components/FAQ/FAQ";
import Header from '../../components/layout/Header';
import Footer from "../Footer";
import Androidz from '../../assets/images/Androidz.png';
import aad from '../../assets/images/aad.svg';
import girlreact from '../../assets/images/girlreact.png';
import hhelth from '../../assets/images/hhelth.png';
import EcommerceSolutions from '../../assets/images/EcommerceSolutions.png';
import networking from '../../assets/images/networking.png';
import banking from '../../assets/images/banking.png';
import Travel from '../../assets/images/Travel.png';
import Media from '../../assets/images/Media.png';
import EntertainmentWebSolutions from '../../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../../assets/images/EdutechSolutions.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const AndroidAppDevelopment = () => {

  const [faqs, setFaqs] = useState([
    {
      question: "Why choose BitFront for mobile app development services?",
      answer:
        "Several reasons make us the chosen partner of startups and established firms. Some of the top ones are --Team of subject matter experts-Multi-industry expertise-Specialization in emerging tech-92% client retention rate-93% on-time delivery",
      open: false
    },
    {
      question: "What is the cost of mobile app development?",
      answer: "The cost of mobile app development services is dependent on a series of factors - number and complexity of features, technology incorporation, number of platforms, and location of the development agency. All these factors together decide the cost of application development.",
      open: false
    },
    {
      question:
        "How to choose a mobile app development platform?",
      answer: "Choosing a mobile app development platform is a decision that varies from ease of adoption, integration support, community, scope of features that it can support, etc.",
      open: false
    },
    {
      question:
        "How much time does custom mobile app development take?",
      answer: "The exact time to create a mobile application depends on its features, the screen sizes, and the frameworks used. Generally, it takes 2 months to around 4–5 months depending on developers, functionalities of the app, platform, and much more.",
      open: false
    },
    {
      question:
        "What solutions are provided by your mobile app development services?",
      answer: "We offer end-to-end development solutions ranging from app consultancy, design, development, QA and testing, app deployment, and maintenance.",
      open: false
    },
    {
      question:
        "What is the difference between cross-platform app development and native mobile app development?",
      answer: "The difference between native and cross-platform application development is the way it is built. While native application development revolves around creating apps for a specific platform, cross-platform development allows developers to use one codebase and deploy the app on multiple platforms.",
      open: false
    },
    {
      question:
        "Will I get post-mobile app development support?",
      answer: "Yes. For the maintenance of the mobile app, you are not required to hire a full-time programmer as we have a flexible spot, which allows you to hire our mobile app developers to provide dedicated app support and maintenance service when required.",
      open: false
    }
  ]);

  const toggleFAQ = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const reviews = [
    {
      rating: 5.0,
      reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
      author: "Founder & CEO, Larrea Wealth Management",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
      author: "Co-Founder & CEO, FLOHOM",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
      author: "Founder & CEO, FilmUp",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
      author: "Founder, Level7 Legal",
      verified: "Verified Review"
    }
  ];
  const categories = [
    { alt: "Healthcare", src: hhelth, title: "Healthcare" },
    { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
    { alt: "Social networking", src: networking, title: "Social networking" },
    { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
    { alt: "Travel", src: Travel, title: "Travel" },
    { alt: "Media", src: Media, title: "Media" },
    { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
    { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
  ];
  return (
    <div>
      <Header />
      <ScrollButton />
      <div className="max-w-7xl h-auto bg-white rounded-2xl m-auto mt-[40px]">
        <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
          <div>
            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] text-[18px] font-semibold"><span className="text-[#0652dd]">Android App</span> Development<br />
              Company</h1>
            <p className="text-[16px]">
              We're the leading custom Android app development company known for <br />
              improving your development plans, creating practical solutions,<br />
              designing smooth experiences, and making apps that matter to users.
            </p>
            <li className=" leading-[30px]">GPS Integration</li>
            <li className=" leading-[30px]">Social media integration</li>
            <li className=" leading-[30px]">Wearable Integration</li>
            <li className=" leading-[30px]">Barcode Integration</li>
            <li className=" leading-[30px]">Third-Party Integration</li>
            <br />
            <button className="transition-all duration-200  rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 p-2 w-[250px] mt-10 h-[42px]">TALK TO OUR DEVELOPERS</button>
          </div>
          <img alt="Android" className=" ml-[40px] w-[450px] h-[343px]" src={Androidz} />
        </div>
      </div>
      <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-[150px] mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
        <div className="flex items-center gap-[0.5rem]">
          <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
          <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
        </div>
        <div className="cont-no">
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
          {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
          <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
          <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
          <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
          <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
          <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
          {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
          <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
          <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
          <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
          <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
          {/* <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" /> */}
          <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
        </div>
      </div>
      {/* / */}
      <div className="bg-white w-full h-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-center items-center gap-16 pt-10">
          <div>
            <h1 className="text-3xl md:text-4xl font-semibold">
              Leading <span className="text-blue-600">Android Application Development</span><br />
              Company In India
            </h1>
            <p className="text-base md:text-lg leading-7 mt-4">
              Android is the top choice for making apps, used by over <b>2.5 billion</b> people<br />
              worldwide in <b>190+ countries</b>. Besides phones, Android runs on many devices<br />
              like tablets, smartwatches, in-car navigation, and TVs. It's the most popular<br />
              mobile system, with <b>70.8%</b> of the market. Businesses of all sizes are<br />
              <b>making Android apps</b> to reach lots of people and grow steadily.
            </p>
            <p className="text-base md:text-lg leading-7 mt-4">
              As a leading <b>Android app development company</b>, we love breaking the<br />
              rules of regular mobile app making. We plan carefully to make sure our<br />
              Android apps work on lots of devices and get people excited to use them.<br />
              Our experienced team makes custom, feature-rich, and functional <b>Android<br />
                app solutions</b> that don't just meet business goals but also revolutionize the<br />
              mobile application world. Our objective is to address complex problems and<br />
              create sustainable competitive advantages for our clients by employing<br />
              a customized methodology that combines technical expertise with deep<br />
              industry knowledge.
            </p>
            <p className="text-base md:text-lg leading-7 mt-4">
              Partner with us for Android app development services and take your<br />
              business to new levels of success and creativity.
            </p>
          </div>
          <img alt="aad" className="w-full md:w-[349px] h-auto" src={aad} />
        </div>
      </div>
      {/* / */}
      <div className="w-full max-w-[1320px] h-auto m-auto mt-10 rounded-lg bg-white px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row justify-center items-center pt-10">
          <div className="lg:w-2/3">
            <h1 className="text-3xl sm:text-4xl font-semibold">
              <span className="text-blue-600">Android App </span>
              Development Services <span className="text-blue-600">We Offer</span>
            </h1>
            <p className="text-base sm:text-lg mt-4">
              Android is known as the ecosystem for startups and firms that keep creativity and scalability as their center focus. We serve the<br />
              digital space with Android app development services known to increase customer engagement, better brand awareness, simplify<br />
              customization, and strengthen security in the digital space.
            </p>
            <div className="mt-6 space-y-4">
              <h5 className="flex items-center text-sm sm:text-base mb-2">
                <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="fa-check mr-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
                </svg>Android App Development Consultation
              </h5>
              <h5 className="flex items-center text-sm sm:text-base mb-2">
                <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="fa-check mr-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
                </svg>Android UI/UX Design
              </h5>
              <h5 className="flex items-center text-sm sm:text-base mb-2">
                <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="fa-check mr-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
                </svg>Custom Android App Development
              </h5>
              <h5 className="flex items-center text-sm sm:text-base mb-2">
                <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="fa-check mr-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
                </svg>Android Software Testing
              </h5>
              <h5 className="flex items-center text-sm sm:text-base mb-2">
                <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="fa-check mr-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
                </svg>Android App Porting
              </h5>
              <h5 className="flex items-center text-sm sm:text-base mb-2">
                <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="fa-check mr-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
                </svg>Multi-Platform Deployment
              </h5>
            </div>
          </div>
          <img alt="girlreact" className="w-full max-w-[370px] h-auto mt-8 lg:mt-0" src={girlreact} />
        </div>
      </div>
      {/* / */}
      <div className="mt-12 px-4 md:px-[30px] py-8 md:p-[30px_280px] w-full max-w-screen-xl h-auto bg-white mx-auto">
        <div className="w-full h-auto bg-[#fafafa] p-5">
          <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start">
            BitFront Reviews 5.0
            <span className="text-[#e62415] flex items-center">
              <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
            </span>
          </p>
          <div className="flex flex-col md:flex-row justify-center items-center gap-7">
            {reviews.map((review, index) => (
              <div key={index} className="w-full md:w-72 h-auto bg-white rounded-lg p-6 hover:shadow-lg">
                <p className="text-lg flex gap-2 items-center">
                  {review.rating}
                  <span className="text-[#e62415] flex items-center">
                    <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                  </span>
                </p>
                <p className="text-base pt-4">
                  "{review.reviewText}"
                  <br /><br />
                  {review.author}
                  <br /><br />
                  {review.verified}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* / */}
      <h1 className="text-[40px] text-center mt-[50px] font-semibold">Android Application Development Process<br />
        <span className="text-[#0652dd]">How do we do it?</span></h1>
      <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
        </div>
      </div>
      {/* / */}
      <h1 className="text-[40px] text-center mt-[60px] font-semibold "><span className="text-[#0652dd]">Industries We Serve</span> for Custom Android Application Development</h1>
      <p className="text-[18px] text-center">
        BitFront has worked with a range of sectors on a global scale to ensure that their Android presence is sustainable, efficient, and ROI friendly. With over 6+ <br />
        years of experience, we have been a part of multiple industry's growth stories.
      </p>
      <div className="flex flex-wrap justify-center items-center gap-7">
        {categories.map((category, index) => (
          <div
            key={index}
            className="hover:shadow-2xl transition-all duration-200 w-full sm:w-[320px] h-[250px] bg-white rounded-[20px] mt-[20px] border-[1px]"
          >
            <div className="py-10 flex justify-center">
              <img alt={category.alt} src={category.src} width="60" height="60" />
            </div>
            <h4 className="text-center">{category.title}</h4>
          </div>
        ))}
      </div>
      {/* / */}
      <div className="bg-blue-700 mb-[40px] w-3/4 h-auto mx-auto text-white text-center p-8 rounded-lg mt-10">
        <h3 className="text-[28px]"> How much does it cost to make an Android App?</h3>
        <p className="text-[18px]">The average cost to develop an Android app starts at $1,000 and goes upwards to $99,000+, depending upon several factors like features, design,<br />
          number of platforms, complexity, technology formation & the geographical location of the Android native app development company.</p>
        <div>
          <button
            className="w-[140px] h-[42px] bg-[#fff] text-[#0652dd] font-semibold rounded-[25px] border-[1px] border-[#0652dd] cursor-pointer mt-[20px]">
            LET'S TALK
          </button>
        </div>
      </div>
      {/* / */}
      <div className=" text-justify xl:ml-96 bg-[#fff] mt-[30px] px-4 lg:px-[3px]">
        <h1 className="text-[#0652dd] text-[28px] font-semibold lg:text-[40px] pt-[30px] text-center lg:text-left">
          Why Choose Us?
        </h1>
        <h4 className="text-[18px] lg:text-[23px] text-center lg:text-left">
          The blend of technical expertise and creativity makes us the right Android development<br />
          team to partner with
        </h4>
        <ul className="list-none text-[#4b4b4b] ">
          <li className="text-[16px] lg:text-[18px]">Multi-platform expertise: 100+ projects launched, including apps for Android smartphones, tablets, wearables, and smart TVs.</li>
          <li className="text-[16px] lg:text-[18px]">100+ Happy Clients generously shared their experience of working with BitFront.</li>
          <li className="text-[16px] lg:text-[18px]">50+ Android developers with 6+ years of experience on average.</li>
          <li className="text-[16px] lg:text-[18px]">1–3 months to develop a market-ready MVP.</li>
          <li className="text-[16px] lg:text-[18px]">UX and UI design in compliance with Google’s official Android design guidelines.</li>
          <li className="text-[16px] lg:text-[18px]">ISO 27001-certified security framework for customer data and privacy protection.</li>
          <li className="text-[16px] lg:text-[18px]">In-house regulatory consultants to ensure app compliance with GDPR, PCI DSS, HIPAA, and other major regulations.</li>
        </ul>
        <div className="flex justify-center lg:justify-start">
          <button className="w-[216px] h-[42px] bg-[#0652dd] text-[#fff] text-[16px] border-[1px] border-[#0652dd] rounded-[20px] mt-[20px] cursor-pointer">
            <b>TALK TO OUR EXPERTS</b>
          </button>
        </div>
      </div>
      {/* / */}
      <div className="container mx-auto px-6 mb-[20px] py-[30px]">
        <h2 className="lg:text-[40px] text-[30px] font-bold lg:leading-[59px] leading-[44px] text-center text-[#192734]">Helping Create <span className="text-[#0652dd]">Tomorrow’s Brands</span></h2>
        <p className="text-center text-base leading-[30px] text-[#4c5a67] font-normal">We are the mobile app development company that shapes success stories.</p>
        <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
          <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
          <div className="w-[344px] hidden md:block absolute top-[83px] right-[134px]">
            <p className="text-lg">
              Re-building Banksathi - A finance app making it easy for financial experts to make money.
            </p>
            <div className="inline-row">
              <div>
                <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">
                  4.4
                  <p className="text-[#DE5B48] text-[10px] flex gap-1">
                    <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                  </p>
                </span>
                <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
              </div>
              <div>
                <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
                <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
              </div>
            </div>
            <h1 className="my-3">1 Million+ downloads</h1>
            <div className="flex gap-4">
              <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
                <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" />
                <p><b>View Case Study</b></p>
              </button>
              <img className="w-[124px] h-[36px] cursor-pointer" alt="img" src="/images2/41p.jpg" />
            </div>
          </div>

          <div>
            <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
            <p className="my-2 text-base leading-[30px] text-[#4c5a67] font-normal">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
              in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
              scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
          </div>
          <div className="flex items-center gap-4">
            <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
            <div>
              <p>Corey Smith </p>
              <p>CEO, Emerge</p>
            </div>
          </div>
        </div>

        <div className="justify-center flex pt-5">
        </div>
      </div>
      <h1 className="container mx-auto text-[24px] font-medium m-auto flex justify-center">FAQs</h1>
      <div className="App">
        <div className="faqs">
          {faqs.map((faq, index) => (
            <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AndroidAppDevelopment
