import React, { useState } from 'react';
import Header from '../../components/layout/Header'
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import 'animate.css';
import RealEstateAppDevelopment from '../../assets/images/RealEstateAppDevelopment.png';
import RealEstateMobileAppDevelopment from '../../assets/images/RealEstateMobileAppDevelopment.png';
import RealEstateWebsiteDevelopment from '../../assets/images/RealEstateWebsiteDevelopment.png';
import AIRealEstateMobileAppDevelopment from '../../assets/images/AIRealEstateMobileAppDevelopment.png';
import EnterpriseRealEstateAppDevelopment from '../../assets/images/EnterpriseRealEstateAppDevelopment.png';
import THzyjcgw from '../../assets/images/THzyjcgw.png';
import MLSSoftwareDevelopment from '../../assets/images/MLSSoftwareDevelopment.png';
import RealEstateMarketplace from '../../assets/images/RealEstateMarketplace.png';
import PropertiesRealEstateBuyers from '../../assets/images/PropertiesRealEstateBuyers.png';
import RealEstateCompaniesSellers from '../../assets/images/RealEstateCompaniesSellers.svg';
import RealEstateAggregators from '../../assets/images/RealEstateAggregators.png';
import LoginSignup from '../../assets/images/LoginSignup.png';
import UserProfileManagement from '../../assets/images/UserProfileManagement.png';
import AIChatbotAssistance from '../../assets/images/AIChatbotAssistance.png';
import searchbarwithfilter from '../../assets/images/searchbarwithfilter.png';
import pushnotificationsss from '../../assets/images/pushnotificationsss.png';
import mapintegration from '../../assets/images/mapintegration.png';
import appointmentreminder from '../../assets/images/appointmentreminder.png';
import propertylisting from '../../assets/images/propertylisting.png';
import manageappointments from '../../assets/images/manageappointments.png';
import calendarreminders from '../../assets/images/calendarreminders.png';
import multiplepaymentgateways from '../../assets/images/multiplepaymentgateways.png';
import manageleads from '../../assets/images/manageleads.png';
import monitorprojects from '../../assets/images/monitorprojects.png';
import recentactivity from '../../assets/images/recentactivity.png';
import comprehensivedashboard from '../../assets/images/comprehensivedashboard.png';
import managelisting from '../../assets/images/managelisting.png';
import managebookings from '../../assets/images/managebookings.png';
import analyticsreportsdd from '../../assets/images/analyticsreportsdd.png';
import transactionmanagement from '../../assets/images/transactionmanagement.png';
import advancechatoption from '../../assets/images/advancechatoption.png';
import dedicateddevelopers from '../../assets/images/dedicateddevelopers.png';
import customersupport from '../../assets/images/customersupport.png';
import customizedsolution from '../../assets/images/customizedsolution.png';
import agiledevelopmentmethod from '../../assets/images/agiledevelopmentmethod.png';
import affordable from '../../assets/images/affordable.png';
import satisfactionclient from '../../assets/images/satisfactionclient.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const RealEstate = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "What is Real estate App Development?",
            answer:
                "Real estate app development involves creating mobile applications that can serve various purposes like property search, listing, virtual tours, transaction management, and communication between buyers, sellers, and agents.",
            open: false
        },
        {
            question: "What are the benefits of Real Estate App for businesses?",
            answer: "Well-developed and feature-rich real estate apps can provide several benefits for businesses, including: 1. Enhanced communication",
            open: false
        },
        {
            question:
                "What is the cost of Real Estate Mobile App Development?",
            answer: "The cost to develop a real estate app is based on various factors like the complexity of the app, UI/UX design, tech stack used, and the location of the development team. However, a basic app might range from $10,000 to $50,000 and an advanced app can go up to $80,000 or above.",
            open: false
        },
        {
            question:
                "What are the top features of a real estate app?",
            answer: "Here are some advanced features that we can deploy in your app- 1. Virtual property tour 2. Chatbot integration",
            open: false
        },
        {
            question:
                "Is there any future for mobile apps in the real estate industry?",
            answer: "Real estate mobile apps are leading the industry and helping to change the overall process. Sellers prefer to upload all their property details over these large social platforms to gain more customers and even buyers visit these apps first to buy a property or rent it over.",
            open: false
        },
        {
            question:
                "How long does it take to develop a Real Estate App?",
            answer: "Various factors influence the timeline for real estate app development like app complexity, size and location of the development team, feature list, and more.",
            open: false
        },
        {
            question:
                "How do I create a real estate app from scratch?",
            answer: "Creating a real estate app from scratch requires planning first. Focus on essential features like property listings with filters and map search.",
            open: false
        }
    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">Real Estate</span> App Development Company</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            BitFront has a team of expert app developers who can help you with custom real estate app development,
                            revolutionizing the way of managing real estate business processes and the way people buy, sell or
                            rent properties while ensuring compliance with industry standards.
                        </p>
                        <li className="mt-2">Property Management</li>
                        <li className="mt-2">Revenue Management</li>
                        <li className="mt-2">AI-Chatbot Assistance</li>
                        <li className="mt-2">Monitor Projects</li>
                        <li className="mt-2">Automatic Speech Recognition</li>
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[280px] mt-10 h-[40px]">
                            TALK TO OUR DEVELOPERS
                        </button>
                    </div>
                    <img alt='Real Estate App Development' className="w-full md:w-[616px] h-auto md:h-[450px] mt-8 md:mt-0 mx-auto" src={RealEstateAppDevelopment} />
                </div>
            </div>

            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-[150px] mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                </div>
            </div>
            <div className="flex flex-wrap justify-center xl:gap-[5rem]">
                <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-tr-[50px] rounded-bl-[30px] p-[20px] border-[#efa54f] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img
                            alt='Real Estate Mobile App Development'
                            className="w-9 h-9"
                            src={RealEstateMobileAppDevelopment} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Real Estate Mobile App Development</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        Our team of real estate app developers can use the latest tech to create a successful Real Estate mobile
                        app that is feature-loaded. We can add cool features like seeing properties with AR/VR, an interest rate
                        calculator, a 360° property tour, scheduling meetings, and more to make your app exciting.
                    </p>
                </div>
                <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[50px] rounded-tr-[30px] rounded-br-[30px] p-[20px] border-[#333a98] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='Real Estate Website Development' className="w-9 h-9" src={RealEstateWebsiteDevelopment} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Real Estate Website Development</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        Develop a strong digital presence and take your real estate to the heights. Get real estate website development
                        services from BitFront and create a website that will leave your competitors behind. Hire real estate app developers,
                        and they will deliver you the best real estate web application development solutions.
                    </p>
                </div>
            </div>
            <div className="flex flex-wrap justify-center xl:gap-[5rem]">
                <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-bl-[30px] rounded-br-[50px] p-[20px] border-[#339836] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='AI Real Estate Mobile App Development' className="w-9 h-9" src={AIRealEstateMobileAppDevelopment} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">AI Real Estate Mobile App Development</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        We can deploy AI features in your real estate app that help you create a personalized experience for your
                        customers. With the help of AI, your customers would get personalized suggestions of properties that would
                        assist you in performing upselling in your store.
                    </p>
                </div>
                <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tr-[30px] rounded-bl-[50px] rounded-br-[30px] p-[20px] border-[#885d24] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='EnterpriseRealEstateAppDevelopment' className="w-9 h-9" src={EnterpriseRealEstateAppDevelopment} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Enterprise Real Estate App Development</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        We customize our property financing apps to suit the requirements of large real estate businesses.
                        Our services include robust software development that helps to achieve strategic business goals.
                    </p>
                </div>
            </div>

            <h1 className=" xl:text-[35px] lg:text-[27px] md:text-[20px] text-[16px] font-semibold text-center mt-10">Our <span className="text-[#0652dd]">Real Estate</span> Solutions</h1>
            <p className="text-[18px] xl:w-[1340px] m-auto font-medium text-center">BitFront as a leading real estate software development
                company believes in delivering complete customer satisfaction by offering an extensive set of software and mobile app
                development for the real estate industry, which includes:
            </p>

            <div className="flex flex-wrap justify-center items-center gap-6 md:gap-10 mt-10 p-4">
                <img
                    alt='THzyjcgw'
                    className="w-full max-w-[300px] md:max-w-[499px] h-auto"
                    src={THzyjcgw}
                />
                <div className="flex flex-col items-start w-full md:max-w-[550px] gap-6">
                    <div className="hover:shadow-custom mt-4 w-full max-w-[550px] h-[65px] border-[#afe5a6] border-2 rounded-lg">
                        <div className="flex p-4 gap-4 items-center">
                            <img alt='MLS Software Development' className="w-9 h-9" src={MLSSoftwareDevelopment} />
                            <h1 className="text-[18px] md:text-[20px] font-medium">MLS Software Development</h1>
                        </div>
                    </div>

                    <div className="hover:shadow-custom mt-4 w-full max-w-[550px] h-[65px] border-[#a6e5e3] border-2 rounded-lg">
                        <div className="flex p-4 gap-4 items-center">
                            <img alt='Real Estate Market place' className="w-9 h-9" src={RealEstateMarketplace} />
                            <h1 className="text-[18px] md:text-[20px] font-medium">Real Estate Marketplace</h1>
                        </div>
                    </div>

                    <div className="hover:shadow-custom mt-4 w-full max-w-[550px] h-[65px] border-[#a6b7e5] border-2 rounded-lg">
                        <div className="flex p-4 gap-4 items-center">
                            <img alt='Properties Real Estate Buyers' className="w-9 h-9" src={PropertiesRealEstateBuyers} />
                            <h1 className="text-[18px] md:text-[20px] font-medium">Properties/Real Estate Buyers</h1>
                        </div>
                    </div>

                    <div className="hover:shadow-custom mt-4 w-full max-w-[550px] h-[65px] border-[#c0a6e5] border-2 rounded-lg">
                        <div className="flex p-4 gap-4 items-center">
                            <img alt='Real Estate Companies Sellers' className="w-9 h-9" src={RealEstateCompaniesSellers} />
                            <h1 className="text-[18px] md:text-[20px] font-medium">Real Estate Companies or Sellers</h1>
                        </div>
                    </div>

                    <div className="hover:shadow-custom mt-4 w-full max-w-[550px] h-[65px] border-[#e5a6aa] border-2 rounded-lg">
                        <div className="flex p-4 gap-4 items-center">
                            <img alt='Real Estate Aggregators' className="w-9 h-9" src={RealEstateAggregators} />
                            <h1 className="text-[18px] md:text-[20px] font-medium">Real Estate Aggregators</h1>
                        </div>
                    </div>
                </div>
            </div>

            <h1 className=" font-semibold mt-[40px] xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center">Top <span className="text-[#0652dd]">Real Estate</span> App Features</h1>
            <p className="text-[18px] text-center mb-[20px]">Our team of real estate app developers helps you build a feature-loaded real estate app.</p>
            <div className="flex flex-wrap justify-center items-start gap-10 p-4">
                {/** First Column */}
                <div className="flex flex-col">
                    {[
                        { img: LoginSignup, text: "Login/Signup" },
                        { img: UserProfileManagement, text: "User Profile Management" },
                        { img: AIChatbotAssistance, text: "AI-Chatbot Assistance" },
                        { img: searchbarwithfilter, text: "Search Bar With Filter" },
                        { img: pushnotificationsss, text: "Push Notifications" },
                        { img: mapintegration, text: "Map Integration" },
                        { img: appointmentreminder, text: "Appointment Reminder" },
                    ].map((item, index) => (
                        <div key={index} className="mb-5 flex items-center hover:shadow-md transition-shadow duration-300">
                            <img alt={item.text} className="w-9 h-9" src={item.img} />
                            <p className="text-lg font-medium ml-5">{item.text}</p>
                        </div>
                    ))}
                </div>

                {/** Second Column */}
                <div className="flex flex-col">
                    {[
                        { img: propertylisting, text: "Property Listing" },
                        { img: manageappointments, text: "Manage Appointments" },
                        { img: calendarreminders, text: "Calendar Reminders" },
                        { img: multiplepaymentgateways, text: "Multiple Payment Gateways" },
                        { img: manageleads, text: "Manage Leads" },
                        { img: monitorprojects, text: "Monitor Projects" },
                        { img: recentactivity, text: "Recent Activity" },
                    ].map((item, index) => (
                        <div key={index} className="mb-5 flex items-center hover:shadow-md transition-shadow duration-300">
                            <img alt={item.text} className="w-9 h-9" src={item.img} />
                            <p className="text-lg font-medium ml-5">{item.text}</p>
                        </div>
                    ))}
                </div>

                {/** Third Column */}
                <div className="flex flex-col">
                    {[
                        { img: comprehensivedashboard, text: "Comprehensive Dashboard" },
                        { img: managelisting, text: "Manage Listings" },
                        { img: managebookings, text: "Manage Bookings" },
                        { img: analyticsreportsdd, text: "Analytics & Reports" },
                        { img: transactionmanagement, text: "Transaction Management" },
                        { img: transactionmanagement, text: "Offers & Discounts" },
                        { img: advancechatoption, text: "Advance Chat Option" },
                    ].map((item, index) => (
                        <div key={index} className="mb-5 flex items-center hover:shadow-md transition-shadow duration-300">
                            <img alt={item.text} className="w-9 h-9" src={item.img} />
                            <p className="text-lg font-medium ml-5">{item.text}</p>
                        </div>
                    ))}
                </div>
            </div>



            <h1 className="font-bold xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Real Estate</span> Software Development Process</h1>
            <p className="text-[18px] text-center m-auto mt-4 xl:w-[600px]">
                To create a scalable real estate app platform we follow a unified process of software development-
            </p>
            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>

            <h1 className=" mt-10 text-center text-[38px] font-semibold"><span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex flex-col md:flex-row justify-center items-center gap-20 mt-8 px-4">
                <div className="w-full max-w-lg text-center md:text-left">
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]">Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg mt-4">
                        We deliver quality-assured solutions making us the best real estate app development
                        company and a clear choice for your success in this competitive industry.
                    </p>
                </div>
                <div className="w-full max-w-3xl">
                    <div className="flex flex-col md:flex-row gap-10">
                        <div className="flex-1">
                            {/** Left Column */}
                            <div className="mt-6 flex items-center">
                                <img alt='dedicated developers' src={dedicateddevelopers} className="w-10 h-10 mr-4" />
                                <h1 className="text-lg font-medium">80+ Dedicated Developers</h1>
                            </div>
                            <div className="mt-6 flex items-center">
                                <img alt='customer support' src={customersupport} className="w-10 h-10 mr-4" />
                                <h1 className="text-lg font-medium">24/7 Customer Support</h1>
                            </div>
                            <div className="mt-6 flex items-center">
                                <img alt='customized solution' src={customizedsolution} className="w-10 h-10 mr-4" />
                                <h1 className="text-lg font-medium">Customized Solutions</h1>
                            </div>
                        </div>

                        <div className="flex-1">
                            {/** Right Column */}
                            <div className="mt-6 flex items-center">
                                <img alt='agile development method' src={agiledevelopmentmethod} className="w-10 h-10 mr-4" />
                                <h1 className="text-lg font-medium">Agile Development Method</h1>
                            </div>
                            <div className="mt-6 flex items-center">
                                <img alt='affordable' src={affordable} className="w-10 h-10 mr-4" />
                                <h1 className="text-lg font-medium">100% Affordable</h1>
                            </div>
                            <div className="mt-6 flex items-center">
                                <img alt='client satisfaction' src={satisfactionclient} className="w-10 h-10 mr-4" />
                                <h1 className="text-lg font-medium">Client Satisfaction</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className=" mt-[40px] m-auto p-[40px] w-full xl:w-[1320px] h-auto xl:h-[147px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[20px] font-semibold text-[#fff]">Have a unique Real Estate Application Development Idea? Trust BitFront to turn it into reality.</h1>
            </div>
            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>

            <div className="container mx-auto px-6 py-[30px]">
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                </div>
            </div>
            <h1 className="faqsopara mt-[40px] font-semibold text-center text-[25px]">FAQs</h1>
            <div className="App">
                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default RealEstate
