import React, { useState } from 'react';
import "react-phone-input-2/lib/bootstrap.css";
import Header from "../../components/layout/Header";
import { IoCallSharp } from "react-icons/io5";
import { IoMailSharp } from "react-icons/io5";
import { FaSkype } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollButton from '../../components/scrollb/ScrollButton';
const Contactus = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   if (!firstName || !lastName || !email || !phone || !description) {
  //     toast.error("Please fill in all fields.", {
  //       position: "top-right",
  //     });
  //     return;
  //   }

  //   toast.success("Message sent successfully!", {
  //     position: "top-right",
  //   });


  //   setFirstName('');
  //   setLastName('');
  //   setEmail('');
  //   setPhone('');
  //   setDescription('');
  //   setSelectedFile(null);
  //   setUserInput('');
  //   generateCaptcha();
  //   setIsVerified(false);
  // };
  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if all required fields are filled
    if (!firstName || !lastName || !email || !phone || !description) {
      toast.error("Please fill in all fields.", {
        position: "top-right",
      });
      return;
    }

    // Check if a file is selected when required (optional file is allowed but it can be checked as per requirement)
    if (!selectedFile) {
      toast.error("Please attach a file before submitting.", {
        position: "top-right",
      });
      return;
    }

    toast.success("Message sent successfully!", {
      position: "top-right",
    });

    // Reset form after successful submission
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setDescription('');
    setSelectedFile(null);
    setUserInput('');
    generateCaptcha();
    setIsVerified(false);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };
  const [captcha, setCaptcha] = useState('');
  const [userInput, setUserInput] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const generateCaptcha = () => {
    const randomStr = Math.random().toString(36).substring(2, 7).toUpperCase();
    setCaptcha(randomStr);
    setUserInput('');
    setErrorMessage('');
    setIsVerified(false);
  };

  const handleSubmita = (e) => {
    e.preventDefault();
    if (userInput === captcha) {
      setIsVerified(true);
      setErrorMessage('');
    } else {
      setIsVerified(false);
      setErrorMessage('Captcha did not match. Please try again.');
    }
  };

  React.useEffect(() => {
    generateCaptcha();
  }, []);
  return (
    <div>
      <Header />
      <ToastContainer />
      <ScrollButton />
      <h1 className="mt-5 xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center font-bold">Get in Touch With Us</h1>
      <div className="xl:w-[1176px] h-auto bg-[#F9FAFA] shadow-custom rounded-[20px] p-2.5 py-2 xl:m-auto lg:m-5 mt-[40px]">
        {/* <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-5"> */}
        <div className='grid lg:grid-cols-2 gap-10 md:grid-cols-1'>
          <div className="lg:m-1 md:m-auto h-auto rounded-[20px] bg-custom-gradienwt">
            <div className="p-[50px]">
              <h1 className="text-[24px] ">Contact Information</h1>
              <h1 className="text-[#4C5A67] font-normal text-base leading-6">Fill up the form and our Team will get back to you within 24 hours.</h1>
            </div>
            <h1 className="transition-all duration-200 flex items-center gap-[15px] text-[20px] text-[#0087F5] cursor-pointer hover:text-[#498dc4] md:pl-[50px] pl-[25px]">
              <IoCallSharp />+91 88662 22169
            </h1>
            <h1 className="transition-all duration-200 flex items-center gap-[15px] text-[20px] text-[#0087F5] cursor-pointer hover:text-[#498dc4] pt-7 md:pl-[50px] pl-[25px]">
              <IoMailSharp />info@bitfrontinfotech.com
            </h1>
            <h1 className="transition-all duration-200 flex items-center gap-[15px] text-[20px] text-[#0087F5] cursor-pointer hover:text-[#498dc4] pt-7 md:pl-[50px] pl-[25px]">
              <FaSkype />BitFront Infotech
            </h1>
            <div className="lg:w-[363px] md:w-[670px] h-[1px] bg-[#959595] m-auto mt-16"></div>
            <p className="flex gap-4 p-[40px]">
              <svg width="82" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11" cy="11" r="11" fill="#0087F5"></circle>
                <path d="M6.41797 11L9.3644 14.2083L15.1263 8.25" stroke="white" strokeWidth="1.5"
                  strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>

              <span className="text-[18px]">
                We guarantee 100% security of your information. We will not share the details you provide above with anyone. Your email won’t be used for spamming.
              </span>
            </p>
          </div>
          <div className="lg:ml-0 md:ml-[100px] lg:mt-0 md:mt-[20px] h-auto">
            <h1 className="text-[19px] font-bold">How do we help ?</h1>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                className="hover:shadow-custom transition-all duration-200 h-[60px] p-4 rounded-[2px]"
              />
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                className="hover:shadow-custom transition-all duration-200 h-[60px] p-4 rounded-[2px]"
              />
            </div>
            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail"
                className="hover:shadow-custom transition-all duration-200 h-[60px] p-4 rounded-[2px]"
              />
              <input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone"
                className="hover:shadow-custom transition-all duration-200 h-[60px] p-4 rounded-[2px]"
              />
            </div>
            <input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Project Description"
              className="hover:shadow-custom transition-all duration-200 mt-8 h-[70px] p-4 w-full rounded-[2px]"
            />
            <label htmlFor="fileUpload">
              <div className="hover:shadow-custom transition-all duration-200 bg-white border border-dashed border-gray-400 p-4 mt-8 flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={`h-6 w-6 ${selectedFile ? 'text-green-500' : 'text-black'}`}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                      <polyline points="17 8 12 3 7 8"></polyline>
                      <line x1="12" y1="3" x2="12" y2="15"></line>
                    </svg>
                    <span className={`${selectedFile ? 'text-green-600' : 'text-black'}`}>
                      Attach a file (optional)
                    </span>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    id="fileUpload"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="fileUpload" className="text-blue-500 cursor-pointer">
                    Browse...
                  </label>
                </div>
                {selectedFile && (
                  <div className="text-sm text-gray-600">{selectedFile.name}</div>
                )}
              </div>
            </label>
            <form onSubmit={handleSubmita}>
              <h2 className="text-1xl font-bold mb-4">Captcha Verification</h2>
              <div className="flex items-center justify-start mb-4">
                <div className="p-2 bg-gray-200 text-lg font-mono">{captcha}</div>
                <button
                  type="button"
                  onClick={generateCaptcha}
                  className="ml-4 bg-blue-500 text-white px-4 py-1 rounded"
                >
                  Refresh
                </button>
              </div>
              <div className='flex items-center gap-1'>
                <input
                  type="text"
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  placeholder="Enter captcha"
                  disabled={isVerified}
                  className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded w-full"
                >
                  Verify
                </button>
              </div>
              {isVerified && (
                <p className="text-green-500 text-sm mt-4">Captcha Verified Successfully!</p>
              )}
              {errorMessage && (
                <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
              )}
            </form>
            <button
              onClick={handleSubmit}
              className="transition-all rounded-[50px] duration-200 mt-8 bg-[#0652dd] w-[130px] border-[1px] border-[#0087F5] hover:bg-white hover:text-[#0087F5] h-[45px] text-[18px] text-[#fff] font-semibold"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className=" w-screen h-[2px] m-auto mt-10 bg-[#bfbebe] mb-[20px]"></div>
    </div>
  )
}

export default Contactus
