import './Career.css';
import React from 'react';
import "react-phone-input-2/lib/bootstrap.css";
import Header from "../../components/layout/Header";
import Footer from "../Footer";
import benefit from '../../assets/images/benefit.png';
import WorkingWeek from '../../assets/images/WorkingWeek.png';
import benefitb from '../../assets/images/benefitb.png'
import benefitc from '../../assets/images/benefitc.png';
import benefitd from '../../assets/images/benefitd.png';
import benefite from '../../assets/images/benefite.png';
import benefitf from '../../assets/images/benefitf.png';
import benefitg from '../../assets/images/benefitg.png';
import benefith from '../../assets/images/benefith.png';
import benefiti from '../../assets/images/benefiti.png';
import benefitj from '../../assets/images/benefitj.png';
import benefitk from '../../assets/images/benefitk.png';
import employ from '../../assets/images/employ.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
import { useNavigate } from "react-router-dom";
import { MdArrowRightAlt } from "react-icons/md";
const Career = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/career/photos');
  };
  return (
    <div>
      <Header />
      <ScrollButton />
      <div className="max-w-7xl h-auto bg-white rounded-2xl ml-[20%] mt-[20px] ">
        <div className="grid lg:grid-cols-2 md:grid-cols-1 items-center gap-4 justify-center p-[30px] mt-[0px]">
          <div>
            <h1 className="Find-col">Find<span className="Your-col"> Your </span>
              <span className="Your-col">Career's</span> Version 2.0 at BitFront Infotech</h1>
            <p className="are-para">We are on a mission to digitalize businesses and need you.</p>
            <button className="btn-open"><b>EXPLORE OPENINGS</b></button>
          </div>
          <img alt='531p.jpg' className="sm:w-[500px] sm:h-[500px] rounded-[40px] w-[300px] h-[200px] ml-[20px] " src="/images2/531p.png" />
        </div>
      </div>
      <h1 className=" text-center text-[40px] font-medium mt-[80px]">Working at BitFront = Being a <span className="text-[#0652dd]">Part<br />
        of Flexible + Creative Culture</span></h1>
      <p className="text-[18px] text-center w-[900px] m-auto">Join Bitfront to transform organizations with cutting-edge technologies and make a
        real impact. We’re committed to growth and always seeking dynamic talent.
        Elevate both your career and our mission. Ready to tackle what matters?</p>
      <div className="max-w-7xl h-auto bg-white m-auto mt-[80px] ">
        <div className="grid sm:grid-cols-2 grid-cols-1 justify-center mt-0 gap-[3rem]">
          <div>
            <h1 className="text-center text-[20px] font-bold">Perks & Benefits</h1>
            <div className="grid sm:grid-cols-2 grid-cols-1  gap-[1rem]">
              <img alt='54p.jpg' className="lg:w-[306px] lg:h-[270px] md:h-[148px] md:w-[256px]" src="/images2/54p.jpg" />
              <img alt='55p.jpg' className="lg:w-[356px] lg:h-[270px] md:h-[148px] md:w-[456px]" src="/images2/55p.jpg" />
            </div>
            <br />
            <img alt='56p.jpg' className="lg:w-[710px] lg:h-[370px] md:h-[182px] m-auto md:w-[336px]" src="/images2/56p.jpg" />
            <br />
            <br />
            <div className="grid sm:grid-cols-2 grid-cols-1  gap-[1rem]">
              <img alt='57p.jpg' className="lg:w-[346px] lg:h-[290px] md:h-[148px] md:w-[256px]" src="/images2/57p.jpg" />
              <img alt='58p.jpg' className="lg:w-[346px] lg:h-[290px] md:h-[148px] md:w-[256px]" src="/images2/58p.jpg" />
            </div>
          </div>
          <div>
            <div className="xl:w-[600px] lg:w-[330px] h-auto bg-[#fff] mt-[15px] mb-[20px] ">
              <div className="flex items-center gap-[1.5rem] pt-[5px] pl-[25px]">
                <img alt='benefit' className="w-[50px] h-[50px]" src={benefit} />
                <p>Flexible Working Models</p>
              </div>
            </div>
            <div className="xl:w-[600px] lg:w-[330px] h-auto bg-[#fff] mt-[15px] mb-[20px] ">
              <div className="flex items-center gap-[1.5rem] pt-[5px] pl-[25px]">
                <img alt='WorkingWeek' className="w-[50px] h-[50px]" src={WorkingWeek} />
                <p>5-Day Working a Week</p>
              </div>
            </div>
            <div className="xl:w-[600px] lg:w-[330px] h-auto bg-[#fff] mt-[15px] mb-[20px] ">
              <div className="flex items-center gap-[1.5rem] pt-[5px] pl-[25px]">
                <img alt='benefitb' className="w-[50px] h-[50px]" src={benefitb} />
                <p>Family Health Insurance</p>
              </div>
            </div>
            <div className="xl:w-[600px] lg:w-[330px] h-auto bg-[#fff] mt-[15px] mb-[20px] ">
              <div className="flex items-center gap-[1.5rem] pt-[5px] pl-[25px]">
                <img alt='benefitc' className="w-[50px] h-[50px]" src={benefitc} />
                <p>Competitive Salaries</p>
              </div>
            </div>
            <div className="xl:w-[600px] lg:w-[330px] h-auto bg-[#fff] mt-[15px] mb-[20px] ">
              <div className="flex items-center gap-[1.5rem] pt-[5px] pl-[25px]">
                <img alt='benefitd' className="w-[50px] h-[50px]" src={benefitd} />
                <p>Performance Appreciation Bonuses</p>
              </div>
            </div>
            <div className="xl:w-[600px] lg:w-[330px] h-auto bg-[#fff] mt-[15px] mb-[20px] ">
              <div className="flex items-center gap-[1.5rem] pt-[5px] pl-[25px]">
                <img alt='benefite' className="w-[50px] h-[50px]" src={benefite} />
                <p>Complimentary Lunches</p>
              </div>
            </div>
            <div className="xl:w-[600px] lg:w-[330px] h-auto bg-[#fff] mt-[15px] mb-[20px] ">
              <div className="flex items-center gap-[1.5rem] pt-[5px] pl-[25px]">
                <img alt='benefitf' className="w-[50px] h-[50px]" src={benefitf} />
                <p>Friendly Leave Policies</p>
              </div>
            </div>
            <div className="xl:w-[600px] lg:w-[330px] h-auto bg-[#fff] mt-[15px] mb-[20px] ">
              <div className="flex items-center gap-[1.5rem] pt-[5px] pl-[25px]">
                <img alt='benefitg' className="w-[50px] h-[50px]" src={benefitg} />
                <p>Celebrating Festivals, Birthdays, And Work<br /> Anniversaries</p>
              </div>
            </div>
            <div className="xl:w-[600px] lg:w-[330px] h-auto bg-[#fff] mt-[15px] mb-[20px] ">
              <div className="flex items-center gap-[1.5rem] pt-[5px] pl-[25px]">
                <img alt='benefith' className="w-[50px] h-[50px]" src={benefith} />
                <p>Growth Opportunities</p>
              </div>
            </div>
            <div className="xl:w-[600px] lg:w-[330px] h-auto bg-[#fff] mt-[15px] mb-[20px] ">
              <div className="flex items-center gap-[1.5rem] pt-[5px] pl-[25px]">
                <img alt='benefiti' className="w-[50px] h-[50px]" src={benefiti} />
                <p>Continuous Learning Opportunities</p>
              </div>
            </div>
            <div className="xl:w-[600px] lg:w-[330px] h-auto bg-[#fff] mt-[15px] mb-[20px] ">
              <div className="flex items-center gap-[1.5rem] pt-[5px] pl-[25px]">
                <img alt='benefitj' className="w-[50px] h-[50px]" src={benefitj} />
                <p>Open Work Culture</p>
              </div>
            </div>
            <div className="xl:w-[600px] lg:w-[330px] h-auto bg-[#fff] mt-[15px] mb-[20px] ">
              <div className="flex items-center gap-[1.5rem] pt-[5px] pl-[25px]">
                <img alt='benefitk' className="w-[50px] h-[50px]" src={benefitk} />
                <p>Dedicated Areas Like Pantries and Play Zones</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h1 className="text-center mt-[48px] xl:text-[40px] lg:text-[30px] md:text-[20px] font-medium text-[26px] ">Current Openings</h1>
      <p className="text-center text-[18px]">Find your next career move with us</p>
      <div className="sm:w-[596px] sm:h-[400px] w-[306px] h-[350px] bg-white mt-[40px] mx-auto rounded-[20px] text-center shadow-[0_7px_29px_0_rgba(100,100,111,0.2)]">
        <h1 className="text-[21px] pt-6 pl-8">Full Stack Developer</h1>
        <div className="sm:w-[450px] w-[300px] h-[1px] mx-auto bg-[#959595]"></div>
        <div className="flex justify-center gap-[50px] mt-4">
          <div className="sm:text-[22px] text-[18px] font-normal sm:font-bold">
            <h1>No of Positions</h1>
            <p className="text-[#0652dd]">1</p>
          </div>
          <div className="sm:text-[22px] text-[18px] font-normal sm:font-bold">
            <h1>Years of experience</h1>
            <p className="text-[#0652dd]">0 - 1</p>
          </div>
        </div>
        <div className="sm:text-[22px] text-[18px] font-normal sm:font-bold">
          <h1>Location</h1>
          <p className="text-[#0652dd]">on site</p>
        </div>
        <a
          // href="https://docs.google.com/forms/d/e/1FAIpQLSc91rhXm-M0TPFv31PEonXZ98gj2bzBRe4sef1tFuSEiHltpA/viewform"
          href='https://docs.google.com/forms/d/1kS5RSN6v-hIplX6nAz9IOKnBFHbpArG4x1mLj5nXygc/prefill'
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="m-[70px] w-[122px] h-[38px] bg-[#0652dd] text-white rounded-full text-[18px] px-[10px] py-[5px] border border-[#0652dd] cursor-pointer">
            Apply Now
          </button>
        </a>

      </div>
      <br />
      <div className="max-w-7xl h-auto bg-white rounded-2xl m-auto ">
        <div className="grid lg:grid-cols-2 md:grid-cols-1 items-center justify-center p-[0px] gap-6">
          <div>
            <h1 className="text-[30px]">
              Unable to find a job role that matches your profile?
            </h1>
            <p className="text-[20px]">
              To know how your skill can fit into the BitFront journey drop in an<br />
              Email on <a href="mailto:info@bitfrontinfotech.com" className="text-[#0652dd]">info@bitfrontinfotech.com</a>
            </p>
          </div>
          <img alt='employ' className="sm:w-[646px] sm:h-[478px] w-[260px] h-[180px]" src={employ} />
        </div>
      </div>
      <h1
        className="text-[25px] flex items-center justify-center font-bold text-[#000000] cursor-pointer"
        onClick={handleClick}
      >
        Go To Gallery.. <MdArrowRightAlt />
      </h1>

      <Footer />
    </div>
  )
}

export default Career;
