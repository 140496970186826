import React, { useState } from 'react';
import Header from '../../components/layout/Header'
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import 'animate.css';
import entertainheroimage from '../../assets/images/entertainheroimage.png';
import Videostreamingapps from '../../assets/images/Videostreamingapps.png';
import Musicstreamingapps from '../../assets/images/Musicstreamingapps.png';
import Gamingapps from '../../assets/images/Gamingapps.png';
import Photoeditingapps from '../../assets/images/Photoeditingapps.png';
import EntertainmentApp from '../../assets/images/EntertainmentApp.png';
import MultimediaEditing from '../../assets/images/MultimediaEditing.png';
import MediaContentManagementDevelopment from '../../assets/images/MediaContentManagementDevelopment.png';
import OTTAppDevelopment from '../../assets/images/OTTAppDevelopment.png';
import MusicAppDevelopment from '../../assets/images/MusicAppDevelopment.png';
import LiveStreamingBroadcasting from '../../assets/images/LiveStreamingBroadcasting.png';
import entertainment from '../../assets/images/entertainment.png';
import OTTcontentmanagement from '../../assets/images/OTTcontentmanagement.png';
import Subscriptionmanagementsolutions from '../../assets/images/Subscriptionmanagementsolutions.png';
import Analyticsreportingsolutions from '../../assets/images/Analyticsreportingsolutions.png';
import Marketingpromotionmanagement from '../../assets/images/Marketingpromotionmanagement.png';
import CustomerSatisfaction from '../../assets/images/CustomerSatisfaction.png';
import QualityAssurance from '../../assets/images/QualityAssurance.png';
import DeliverTime from '../../assets/images/DeliverTime.png';
import SupportMaintenance from '../../assets/images/SupportMaintenance.png';
import FreeConsultation from '../../assets/images/FreeConsultation.png';
import TrendyDesignTechnologies from '../../assets/images/TrendyDesignTechnologies.png';
import CosteffectiveSolutions from '../../assets/images/CosteffectiveSolutions.png';
import TimetoTimeUpgradation from '../../assets/images/TimetoTimeUpgradation.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const Entertainment = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "What's the future of the media entertainment industry in app development?",
            answer:
                "After COVID-19, the media and entertainment industry has jumped onto the internet and technology bandwagon. Websites, mobile apps, and entertainment services like Netflix have become really popular.",
            open: false
        },
        {
            question: "What kinds of apps do your entertainment app development company build?",
            answer: "We offer a wide range of products that we'll help you create, using our best effort, experience, expertise, and knowledge. Simply share your app idea with us, and we'll craft a dynamic product that exceeds your expectations.",
            open: false
        },
        {
            question:
                "Why are mobile apps important for the media and entertainment industry?",
            answer: "Mobile apps are really important as it adds more availability, better flexibility, and better exposure to entertainment to the users.Moreover, these days all mobile devices are designed to provide a large screen, and the full-screen view is more encouraging.",
            open: false
        },
        {
            question:
                "How much does entertainment app development cost?",
            answer: "The cost to build an entertainment app depends on features, complexity, platform, and entertainment app development company rates. A simple app can cost around $8,000, while a more complex one can cost over $20,000. The cost can vary significantly based on the project's requirements and specifications.",
            open: false
        },
        {
            question:
                "How long does it take to develop media & entertainment solutions?",
            answer: "There is no exact answer to this question because the time needed for media and entertainment software development largely depends on the functionalities, development platform, and experience level of app developers.",
            open: false
        },
        {
            question:
                "Should I invest in video streaming or OTT app development?",
            answer: "Yes, of course! This idea is inspired by popular video streaming apps like Netflix and Amazon Prime. We've all seen how much people enjoy watching content on these platforms.",
            open: false
        },
        {
            question:
                "Should I invest in music streaming app development?",
            answer: "From starting a day with a workout to riding to the workplace to easing your mind with peaceful music, we all listen to music. Whether the music streaming app is Spotify, Youtube music, or anything else, users go for the best playlist and suggestions.",
            open: false
        }
    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">Entertainment</span> App Development</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            We are recognized in the digital world as a media and entertainment app development agency. Our focus
                            is on increasing revenue for media houses while delivering captivating experiences to end users.
                        </p>
                        <li className="mt-2">Subscription management solutions</li>
                        <li className="mt-2">Data management</li>
                        <li className="mt-2">Multiple payment modules</li>
                        <li className="mt-2">Analytics and reporting solutions</li>
                        <li className="mt-2">Marketing and promotion management</li>
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[280px] mt-10 h-[40px]">
                            TALK TO OUR DEVELOPERS
                        </button>
                    </div>
                    <img alt='entertainheroimage' className="w-full md:w-[596px] h-auto md:h-[386px] mt-8 md:mt-0 mx-auto" src={entertainheroimage} />
                </div>
            </div>

            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-[150px] mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                </div>
            </div>
            <div className="flex flex-wrap justify-center xl:gap-[5rem]">
                <div>
                    <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#988c33] border-[2px]">
                        <div className="flex items-center gap-1">
                            <img alt='Videostreamingapps' className="w-[40px] h-[40px]" src={Videostreamingapps} />
                            <h4 className="text-[18px] sm:text-[20px] font-bold">Video streaming apps</h4>
                        </div>
                        <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                            At BitFront, we specialize in building travel planning and organizing applications which we power with
                            features like blogs, videos, multi-language supports, events details, and more. The intent we follow
                            in the application is simple - help travelers make an itinerary effortlessly.
                        </p>
                    </div>
                    <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#339882] border-[2px]">
                        <div className="flex items-center gap-1">
                            <img alt='Musicstreamingapps' className="w-[40px] h-[40px]" src={Musicstreamingapps} />
                            <h4 className="text-[18px] sm:text-[20px] font-bold">Music streaming apps</h4>
                        </div>
                        <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                            In our role as a hotel booking app development company, we hold an expertise in creating apps across models
                            like merchant and aggregate. Whether you are a hotel looking for digital solutions or an entrepreneur
                            looking to create the next Expedia, we have the skillset.
                        </p>
                    </div>
                </div>
                <div>
                    <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#984133] border-[2px]">
                        <div className="flex items-center gap-1">
                            <img alt='Gamingapps' className="w-[40px] h-[40px]" src={Gamingapps} />
                            <h4 className="text-[18px] sm:text-[20px] font-bold">Gaming apps</h4>
                        </div>
                        <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                            Every travel plan gets formalized at the ticket booking stage. At BitFront, we specialize in making the booking
                            process hassle-free by using features like real-time availability and price comparison, currency converter,
                            multiple payment modules, travel information download and sharing, and more.
                        </p>
                    </div>
                    <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#337898] border-[2px]">
                        <div className="flex items-center gap-1">
                            <img alt='Photoeditingapps' className="w-[40px] h-[40px]" src={Photoeditingapps} />
                            <h4 className="text-[18px] sm:text-[20px] font-bold">Photo editing apps</h4>
                        </div>
                        <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                            Photo editing has become a crucial element of entertainment, social apps. Our team of media and entertainment
                            app developers specialize in creating AR filters, multi-lens formats, social media integrations for easy
                            sharing, and different payment models, etc. Features that make every photo editing app the most used one.
                        </p>
                    </div>
                </div>
            </div>

            <h1 className="text-2xl md:text-[35px] font-semibold text-center mt-10">
                Our <span className="text-[#0652dd]">Media and Entertainment App</span> Development Solutions
            </h1>
            <p className="text-base md:text-[18px] w-full max-w-[90%] md:max-w-[1340px] m-auto font-medium text-center mt-4">
                BitFront offers cutting-edge media and entertainment app development services and solutions tailored to meet the
                specific needs of various sectors, including media houses, publication houses, entertainment studios, multiplex
                and theatre chains, amusement parks, as well as radio and TV broadcasting stations.
            </p>


            <div className="flex flex-wrap justify-center items-center gap-8 md:gap-[5rem] mt-[40px]">
                <img
                    alt="EntertainmentApp"
                    className="w-full md:w-[499px] h-auto md:h-[456px] object-cover"
                    src={EntertainmentApp}
                />
                <div className="flex flex-col gap-8">
                    <div className="w-full md:w-[550px] border-[#afe5a6] border-[2px] rounded-[10px]">
                        <div className="flex p-4 md:p-[15px] gap-4 md:gap-[2rem] items-center">
                            <img alt="MultimediaEditing" className="w-[35px] h-[35px]" src={MultimediaEditing} />
                            <h1 className="text-lg md:text-[20px] font-medium">Multimedia Editing & Sharing Development</h1>
                        </div>
                    </div>

                    <div className="w-full md:w-[550px] border-[#a6e5e3] border-[2px] rounded-[10px]">
                        <div className="flex p-4 md:p-[15px] gap-4 md:gap-[2rem] items-center">
                            <img alt="MediaContent" className="w-[35px] h-[35px]" src={MediaContentManagementDevelopment} />
                            <h1 className="text-lg md:text-[20px] font-medium">Media Content Management Development</h1>
                        </div>
                    </div>

                    <div className="w-full md:w-[550px] border-[#a6b7e5] border-[2px] rounded-[10px]">
                        <div className="flex p-4 md:p-[15px] gap-4 md:gap-[2rem] items-center">
                            <img alt="OTTAppDevelopment" className="w-[35px] h-[35px]" src={OTTAppDevelopment} />
                            <h1 className="text-lg md:text-[20px] font-medium">OTT App Development</h1>
                        </div>
                    </div>

                    <div className="w-full md:w-[550px] border-[#c0a6e5] border-[2px] rounded-[10px]">
                        <div className="flex p-4 md:p-[15px] gap-4 md:gap-[2rem] items-center">
                            <img alt="MusicAppDevelopment" className="w-[35px] h-[35px]" src={MusicAppDevelopment} />
                            <h1 className="text-lg md:text-[20px] font-medium">Music Streaming App Development</h1>
                        </div>
                    </div>

                    <div className="w-full md:w-[550px] border-[#e5a6aa] border-[2px] rounded-[10px]">
                        <div className="flex p-4 md:p-[15px] gap-4 md:gap-[2rem] items-center">
                            <img alt="LiveStreamingBroadcasting" className="w-[35px] h-[35px]" src={LiveStreamingBroadcasting} />
                            <h1 className="text-lg md:text-[20px] font-medium">Live Streaming & Broadcasting</h1>
                        </div>
                    </div>
                </div>
            </div>

            <h1 className=" mt-8 xl:text-[38px] lg:text-[29px] md:text-[20px] text-[16px] font-semibold text-center">Covering the <span className="text-[#0652dd]">Media and Entertainment </span>
                Industry by 360 Degrees</h1>
            <p className="text-[18px] font-medium text-center m-auto xl:w-[930px]">At BitFront, our vision is to revolutionize the media
                and entertainment industry by creating engaging and innovative experiences. We build software that impacts the ROI and
                experience of both media houses and the end users.</p>
            <div className="flex flex-wrap justify-center gap-8 md:gap-[5rem] mt-[60px]">
                <div className="flex flex-col items-start">
                    <div className="mt-6 flex items-center gap-4 md:gap-[2rem]">
                        <img alt="OTTcontentmanagement" className="w-[35px] h-[35px]" src={OTTcontentmanagement} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">OTT content management</h1>
                    </div>
                    <div className="mt-6 flex items-center gap-4 md:gap-[2rem]">
                        <img alt="Subscriptionmanagementsolutions" className="w-[35px] h-[35px]" src={Subscriptionmanagementsolutions} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Subscription management solutions</h1>
                    </div>
                    <div className="mt-6 flex items-center gap-4 md:gap-[2rem]">
                        <img alt="MediaContentManagementDevelopment" className="w-[35px] h-[35px]" src={MediaContentManagementDevelopment} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Data management</h1>
                    </div>
                    <div className="mt-6 flex items-center gap-4 md:gap-[2rem]">
                        <img alt="MultimediaEditing" className="w-[35px] h-[35px]" src={MultimediaEditing} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Multiple payment modules</h1>
                    </div>
                    <div className="mt-6 flex items-center gap-4 md:gap-[2rem]">
                        <img alt="Analyticsreportingsolutions" className="w-[35px] h-[35px]" src={Analyticsreportingsolutions} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Analytics and reporting solutions</h1>
                    </div>
                    <div className="mt-6 flex items-center gap-4 md:gap-[2rem]">
                        <img alt="Marketingpromotionmanagement" className="w-[35px] h-[35px]" src={Marketingpromotionmanagement} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Marketing and promotion management</h1>
                    </div>
                </div>

                <img alt="entertainment" className="w-full md:w-[406px] h-auto md:h-[476px]" src={entertainment} />

                <div className="w-full md:w-[400px] flex flex-col items-start">
                    <div className="mt-6 flex items-center gap-4 md:gap-[2rem]">
                        <img alt="Videostreamingapps" className="w-[35px] h-[35px]" src={Videostreamingapps} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Video streaming apps</h1>
                    </div>
                    <div className="mt-6 flex items-center gap-4 md:gap-[2rem]">
                        <img alt="Musicstreamingapps" className="w-[35px] h-[35px]" src={Musicstreamingapps} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Music streaming apps</h1>
                    </div>
                    <div className="mt-6 flex items-center gap-4 md:gap-[2rem]">
                        <img alt="Photoeditingapps" className="w-[35px] h-[35px]" src={Photoeditingapps} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Photo editing solutions</h1>
                    </div>
                    <div className="mt-6 flex items-center gap-4 md:gap-[2rem]">
                        <img alt="Gamingapps" className="w-[35px] h-[35px]" src={Gamingapps} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Gaming apps</h1>
                    </div>
                </div>
            </div>


            <h1 className="font-bold xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Entertainment App</span> Development Process</h1>
            <p className="text-[18px] text-center m-auto mt-4 xl:w-[600px]">
                Every stage of your media & entertainment app development services
                is engineered with robustness and intuitiveness in center focus.
            </p>
            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>

            <h1 className=" mt-10 text-center xl:text-[38px] lg:text-[29px] md:text-[20px] text-[16px] font-semibold"><span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex flex-wrap justify-center items-center gap-8 md:gap-[5rem] mt-[30px]">
                <div className="w-full md:w-[600px] text-center md:text-left px-4">
                    <h1 className="text-2xl md:text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-base md:text-lg mt-4">
                        We have helped over 6+ entertainment houses make a digital presence within budget and planned deadlines.
                    </p>
                </div>

                <div className="flex flex-col md:flex-row gap-8 md:gap-[5rem]">
                    <div className="w-full md:w-[300px] flex flex-col items-center md:items-start px-4">
                        <div className="mt-6 flex items-center gap-4">
                            <img alt='CustomerSatisfaction' className="w-[40px] h-[40px]" src={CustomerSatisfaction} />
                            <h1 className="text-[16px] md:text-[18px] font-medium">100% Customer Satisfaction</h1>
                        </div>
                        <div className="mt-6 flex items-center gap-4">
                            <img alt='QualityAssurance' className="w-[40px] h-[40px]" src={QualityAssurance} />
                            <h1 className="text-[16px] md:text-[18px] font-medium">Quality Assurance</h1>
                        </div>
                        <div className="mt-6 flex items-center gap-4">
                            <img alt='DeliverTime' className="w-[40px] h-[40px]" src={DeliverTime} />
                            <h1 className="text-[16px] md:text-[18px] font-medium">Deliver on Time</h1>
                        </div>
                        <div className="mt-6 flex items-center gap-4">
                            <img alt='SupportMaintenance' className="w-[40px] h-[40px]" src={SupportMaintenance} />
                            <h1 className="text-[16px] md:text-[18px] font-medium">24*7 Support & Maintenance</h1>
                        </div>
                    </div>

                    <div className="w-full md:w-[325px] flex flex-col items-center md:items-start px-4">
                        <div className="mt-6 flex items-center gap-4">
                            <img alt='FreeConsultation' className="w-[40px] h-[40px]" src={FreeConsultation} />
                            <h1 className="text-[16px] md:text-[18px] font-medium">Free Consultation</h1>
                        </div>
                        <div className="mt-6 flex items-center gap-4">
                            <img alt='TrendyDesignTechnologies' className="w-[40px] h-[40px]" src={TrendyDesignTechnologies} />
                            <h1 className="text-[16px] md:text-[18px] font-medium">Trendy Design & Technologies</h1>
                        </div>
                        <div className="mt-6 flex items-center gap-4">
                            <img alt='CosteffectiveSolutions' className="w-[40px] h-[40px]" src={CosteffectiveSolutions} />
                            <h1 className="text-[16px] md:text-[18px] font-medium">Cost Effective</h1>
                        </div>
                        <div className="mt-6 flex items-center gap-4">
                            <img alt='TimetoTimeUpgradation' className="w-[40px] h-[40px]" src={TimetoTimeUpgradation} />
                            <h1 className="text-[16px] md:text-[18px] font-medium">Time to Time Upgradation</h1>
                        </div>
                    </div>
                </div>
            </div>


            <div className=" mt-[40px] m-auto p-[40px] w-full xl:w-[1320px] h-auto xl:h-[147px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[20px] font-semibold text-[#fff]">Are You Ready To Transform Your Business With An Entertainment App?</h1>
            </div>
            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>

            <div className="container mx-auto px-6 py-[30px]">
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                </div>
            </div>
            <h1 className="faqsopara text-center mt-[40px] font-semibold text-[25px]">FAQs</h1>
            <div className="App">

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Entertainment
