import React, { useState } from 'react';
import Header from '../../components/layout/Header';
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import 'animate.css';
import OnDemandDevelopment from '../../assets/images/OnDemandDevelopment.png';
import OnDemandTaxiAppDevelopment from '../../assets/images/OnDemandTaxiAppDevelopment.png';
import OnDemandHomeServicesAppDevelopment from '../../assets/images/OnDemandHomeServicesAppDevelopment.png';
import OnDemandDeliveryAppDevelopment from '../../assets/images/OnDemandDeliveryAppDevelopment.png';
import OnDemandLaundryAppDevelopment from '../../assets/images/OnDemandLaundryAppDevelopment.png';
import FoodDeliveryApp from '../../assets/images/FoodDeliveryApp.png';
import BeautyandWellnessApps from '../../assets/images/BeautyandWellnessApps.png';
import PetCareApp from '../../assets/images/PetCareApp.png';
import HomeServicesApp from '../../assets/images/HomeServicesApp.png';
import GroceryDeliveryApp from '../../assets/images/GroceryDeliveryApp.png';
import Onboarding from '../../assets/images/Onboarding.png';
import BookingMatching from '../../assets/images/BookingMatching.png';
import Pushnotifications from '../../assets/images/Pushnotifications.png';
import RealtimeTrackingApps from '../../assets/images/RealtimeTrackingApps.png';
import ServiceSelection from '../../assets/images/ServiceSelection.png';
import MultiplePaymentOptions from '../../assets/images/MultiplePaymentOptions.png';
import OrderRequestsandDetails from '../../assets/images/OrderRequestsandDetails.png';
import AdvancedRouteOptimizations from '../../assets/images/AdvancedRouteOptimizations.png';
import ProofofDelivery from '../../assets/images/ProofofDelivery.png';
import PaymentReports from '../../assets/images/PaymentReports.png';
import ServiceAvailability from '../../assets/images/ServiceAvailability.png';
import OrdersBookingManagement from '../../assets/images/OrdersBookingManagement.png';
import ServiceTracking from '../../assets/images/ServiceTracking.png';
import OverseeReviewsandFeedback from '../../assets/images/OverseeReviewsandFeedback.png';
import ServiceProvidersManagement from '../../assets/images/ServiceProvidersManagement.png';
import AnalyticsReports from '../../assets/images/AnalyticsReports.png';
import PricingandCommissionManagement from '../../assets/images/PricingandCommissionManagement.png';
import AgileDevelopmentMethodology from '../../assets/images/AgileDevelopmentMethodology.png';
import EndtoendSupport from '../../assets/images/EndtoendSupport.png';
import Usercentricsolutionss from '../../assets/images/Usercentricsolutionss.png';
import AccesstoToptierTalent from '../../assets/images/AccesstoToptierTalent.png';
import ScalabilityAssurance from '../../assets/images/ScalabilityAssurance.png';
import DesignedArchitecture from '../../assets/images/DesignedArchitecture.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const OnDemandAppDevelopment = () => {
    const [faqs, setFaqs] = useState([
        {
            question: "What is on-demand app development?",
            answer:
                "An on-demand app is a web or mobile solution that allows users to immediately receive or schedule services, or instantly buy products from a specific company. It may also offer comparisons among various on-demand providers, aiding users in selecting the most suitable option.",
            open: false
        },
        {
            question: "How much does on-demand app development cost?",
            answer: "The cost of creating an on-demand app can vary from $40,000 to $200,000, or more. Numerous factors influence the total cost, including the industry, complexity, project timeline, location of the development company, UI/UX design, and ongoing maintenance. Reach out to our on-demand developers for precise cost estimates as per your specific business needs.",
            open: false
        },
        {
            question:
                "How much time does it take to build an on-demand app?",
            answer: "The time frame to build an on-demand app varies as per the overall complexity of the app. For instance, developing a highly complex app with an extensive feature list will take around 12 to 14 months. On the other hand, a simple app with minimal features will take around 5 to 6 months.",
            open: false
        },
        {
            question:
                "Why should a business have an on-demand service app?",
            answer: "There are numerous benefits for businesses to have an on-demand services app, such as endless business opportunities, gaining a competitive advantage, suitability for all types of businesses, delivering value to consumers, and attracting investors.",
            open: false
        },
        {
            question:
                "Do you create MVP for on-demand apps?",
            answer: "Yes, of course. We create MVP for on-demand app projects as per client requirements.",
            open: false
        },
        {
            question:
                "What industries can benefit from on demand app development?",
            answer: "Almost any industry that links consumers with services or products can benefit from on-demand apps. This includes transportation, food and grocery delivery, healthcare, home services, logistics, beauty, and wellness.",
            open: false
        },
        {
            question:
                "Will I get post-delivery support?",
            answer: "Yes. You don't need to hire a full-time engineer for maintaining your on-demand app solution. We offer a flexible bucket model where you can hire our mobile app developers for dedicated support and maintenance services whenever you need them.",
            open: false
        }
    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">On-Demand</span> App Development Company</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            We create strong on-demand mobility solutions that make your service super fast. Whether it's transportation,
                            healthcare, or retail, we bring your business right to customers' doors, easily.
                        </p>
                        <li className="mt-2">Social Media Integration</li>
                        <li className="mt-2">In-app payments</li>
                        <li className="mt-2">Instant alerts</li>
                        <li className="mt-2">Geo IP location</li>
                        <li className="mt-2">Analytics</li>
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[280px] mt-10 h-[40px]">
                            TALK TO OUR DEVELOPERS
                        </button>
                    </div>
                    <img alt='OnDemandDevelopment' className="w-full md:w-[483px] h-auto md:h-[380px] mt-8 md:mt-0 mx-auto" src={OnDemandDevelopment} />
                </div>
            </div>
            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-[150px] mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                </div>
            </div>

            <div className="flex flex-wrap justify-center xl:gap-[5rem]">
                <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-tr-[50px] rounded-bl-[30px] p-[20px] border-[#efa54f] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='On Demand taxi' className="w-9 h-9" src={OnDemandTaxiAppDevelopment} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">On-Demand Taxi App Development</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        Our team can create a taxi booking app for you that's easy to use and interactive. It will have features
                        like Ola and Uber, such as automatic booking, tracking your ride in real-time, reliable customer support,
                        and the ability to cancel rides smoothly.
                    </p>
                </div>
                <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[50px] rounded-tr-[30px] rounded-br-[30px] p-[20px] border-[#333a98] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='OnDemand Home Services' className="w-9 h-9" src={OnDemandHomeServicesAppDevelopment} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">On-Demand Home Services App Development</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        We're experts in making on-demand home services apps that you can use whenever you need help with things like plumbing
                        or cleaning. Our focus is on making it easy for you to book services quickly, get help when you need it, and have a
                        smooth experience using the app.
                    </p>
                </div>
            </div>
            <div className="flex flex-wrap justify-center xl:gap-[5rem]">
                <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-bl-[30px] rounded-br-[50px] p-[20px] border-[#339836] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='OnDemand Delivery ' className="w-9 h-9" src={OnDemandDeliveryAppDevelopment} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">On-Demand Delivery App Development</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        We're a company that specializes in creating apps for on-demand delivery. Our apps can handle different types
                        of deliveries like medicine, flowers, fuel, food, and groceries. With our help, your grocery deliveries can
                        be quick and smooth. We make ordering easy, keep track of deliveries in real time, and ensure reliable service.
                    </p>
                </div>
                <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tr-[30px] rounded-bl-[50px] rounded-br-[30px] p-[20px] border-[#885d24] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='OnDemand Laundry' className="w-9 h-9" src={OnDemandLaundryAppDevelopment} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">On-Demand Laundry App Development</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        We're experts at creating an easy laundry experience for your customers with our on-demand laundry app development
                        services. Our custom apps are designed to save time and make scheduling pick-ups and deliveries simple with a
                        user-friendly interface.
                    </p>
                </div>
            </div>


            <h1 className=" text-[35px] font-semibold text-center mt-10">Innovative <span className="text-[#0652dd]">On-Demand</span> App Solutions</h1>
            <p className="text-[18px] xl:w-[940px] m-auto font-medium text-center">At BitFront, we hold the market understanding and technical expertise to convert every service-based
                business into an on-demand business model.</p>

            <div className="flex flex-wrap justify-center items-center gap-12 mt-10">
                <img
                    alt='sapphiresolutions'
                    className="w-[499px] h-[486px] object-contain"
                    src="https://www.sapphiresolutions.net/images/on_demand_app/banner.svg"
                />
                <div className="flex flex-col xl:gap-10">
                    {[
                        {
                            title: "Food Delivery App",
                            imgSrc: FoodDeliveryApp,
                            borderColor: "#afe5a6"
                        },
                        {
                            title: "Beauty and Wellness Apps",
                            imgSrc: BeautyandWellnessApps,
                            borderColor: "#a6e5e3"
                        },
                        {
                            title: "Pet Care App",
                            imgSrc: PetCareApp,
                            borderColor: "#a6b7e5"
                        },
                        {
                            title: "Home Services App",
                            imgSrc: HomeServicesApp,
                            borderColor: "#c0a6e5"
                        },
                        {
                            title: "Grocery Delivery App",
                            imgSrc: GroceryDeliveryApp,
                            borderColor: "#e5a6aa"
                        }
                    ].map((item, index) => (
                        <div
                            key={index}
                            className={`hover:shadow-custom mt-10 w-full xl:w-[550px] h-auto xl:h-[65px] border-2 rounded-lg`}
                            style={{ borderColor: item.borderColor }}
                        >
                            <div className="flex p-4 gap-8 items-center">
                                <img
                                    alt={item.title}
                                    className="w-9 h-9"
                                    src={item.imgSrc}
                                />
                                <h1 className="text-xl font-medium">{item.title}</h1>
                            </div>
                        </div>
                    ))}
                </div>
            </div>


            <h1 className=" font-semibold mt-[40px] xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center">App Features for <span className="text-[#0652dd]">Service Providers, Customers
            </span> and<span className="text-[#0652dd]"> Admins</span></h1>
            <p className=" xl:w-[910px] m-auto text-center mb-[20px]">Developing an on-demand application is equal to building three applications - Customer, Service Provider,
                and Admin. At BitFront, we specialize in creating an efficient experience for all the stakeholders.</p>
            <div className="flex flex-col md:flex-row justify-center items-center gap-16 p-8">
                {[
                    [
                        { title: "Onboarding", imgSrc: Onboarding },
                        { title: "Booking/Matching", imgSrc: BookingMatching },
                        { title: "Push Notifications", imgSrc: Pushnotifications },
                        { title: "Real-time Tracking", imgSrc: RealtimeTrackingApps },
                        { title: "Service Selection", imgSrc: ServiceSelection },
                        { title: "Multiple Payment Options", imgSrc: MultiplePaymentOptions },
                    ],
                    [
                        { title: "Order Requests and Details", imgSrc: OrderRequestsandDetails },
                        { title: "Advanced Route Optimizations", imgSrc: AdvancedRouteOptimizations },
                        { title: "Proof of Delivery", imgSrc: ProofofDelivery },
                        { title: "Payment Reports", imgSrc: PaymentReports },
                        { title: "Push Notifications", imgSrc: Pushnotifications },
                        { title: "Service Availability", imgSrc: ServiceAvailability },
                    ],
                    [
                        { title: "Orders & Booking Management", imgSrc: OrdersBookingManagement },
                        { title: "Service Tracking", imgSrc: ServiceTracking },
                        { title: "Oversee Reviews and Feedback", imgSrc: OverseeReviewsandFeedback },
                        { title: "Service Providers Management", imgSrc: ServiceProvidersManagement },
                        { title: "Analytics & Reports", imgSrc: AnalyticsReports },
                        { title: "Pricing and Commission Management", imgSrc: PricingandCommissionManagement },
                    ],
                ].map((column, columnIndex) => (
                    <div key={columnIndex}>
                        {column.map((item, index) => (
                            <div key={index} className="mb-5 flex items-center">
                                <img alt={item.title} className="w-9 h-9" src={item.imgSrc} />
                                <p className="text-lg font-medium ml-5">{item.title}</p>
                            </div>
                        ))}
                    </div>
                ))}
            </div>


            <h1 className="font-bold xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> On-demand</span> App Development Process</h1>
            <p className="text-[18px] text-center m-auto mt-4 xl:w-[600px]">
                We add creativity and excellence in every stage of the on-demand app development process.
            </p>
            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>

            <h1 className=" mt-10 text-center xl:text-[38px] lg:text-[29px] md:text-[20px] text-[16px] font-semibold"><span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex flex-col md:flex-row justify-center items-center gap-20 mt-8 px-4">
                <div className="w-full md:w-[600px]">
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]">Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg">
                        At BitFront, we excel in blending market expertise with cutting-edge technology and features. This
                        specialization establishes us as the trusted on-demand app development company in India.
                    </p>
                </div>
                <div className="flex flex-col md:flex-row gap-8">
                    {[
                        [
                            { title: "Agile Development Methodology", imgSrc: AgileDevelopmentMethodology },
                            { title: "End-to-end Support", imgSrc: EndtoendSupport },
                            { title: "User-centric solutions", imgSrc: Usercentricsolutionss },
                        ],
                        [
                            { title: "Access to Top-tier Talent", imgSrc: AccesstoToptierTalent },
                            { title: "Scalability Assurance", imgSrc: ScalabilityAssurance },
                            { title: "Designed Architecture", imgSrc: DesignedArchitecture },
                        ],
                    ].map((column, index) => (
                        <div key={index} className="flex flex-col gap-6">
                            {column.map((item, itemIndex) => (
                                <div key={itemIndex} className="flex items-center gap-4">
                                    <img alt={item.title} className="w-9 h-9" src={item.imgSrc} />
                                    <h1 className="text-[18px] font-medium">{item.title}</h1>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>

            <div className=" mt-[40px] m-auto p-[40px] w-full xl:w-[1320px] h-auto xl:h-[97px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[20px] font-semibold text-[#fff]">Collaborate for a custom on-demand app and add value to customer experience.</h1>
            </div>
            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className=" text-center">Bring your App Ideas to Life</p>

            <div className="container mx-auto px-6 py-[30px]">
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                </div>
            </div>
            <h1 className="faqsopara mt-[40px] text-center font-semibold text-[25px]">FAQs</h1>
            <div className="App">

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default OnDemandAppDevelopment
