import './Flutter.css';
import { FaStar } from "react-icons/fa6";
import { FaArrowCircleRight } from "react-icons/fa";
import React, { useState } from 'react';
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from 'react-router-dom';
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import Header from "../../components/layout/Header";
import FlutterAppDevelopment from '../../assets/images/FlutterAppDevelopment.png';
import LeadingFlutterAppDevelopment from '../../assets/images/LeadingFlutterAppDevelopment.png';
import iOSAppDevelopmentConsultation from '../../assets/images/iOSAppDevelopmentConsultation.png';
import iOSUIUXDesign from '../../assets/images/iOSUIUXDesign.png';
import CustomiOSAppDevelopment from '../../assets/images/CustomiOSAppDevelopment.png';
import iOSAppDevelopment from '../../assets/images/iOSAppDevelopment.png';
import hhelth from '../../assets/images/hhelth.png';
import EcommerceSolutions from '../../assets/images/EcommerceSolutions.png';
import networking from '../../assets/images/networking.png';
import banking from '../../assets/images/banking.png';
import Travel from '../../assets/images/Travel.png';
import Media from '../../assets/images/Media.png';
import EntertainmentWebSolutions from '../../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../../assets/images/EdutechSolutions.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const Flutter = () => {
  const navigate = useNavigate();

  const handleClickContactus = () => {
    navigate('/Contact-us');
  };

  const [faqs, setFaqs] = useState([
    {
      question: "Custom-Based Solutions",
      answer:
        "We attentively consider every requirement, delivering fully personalized solutions for your business at an affordable price. Get in touch with us for a reliable OTT solution.",
      open: false
    },
    {
      question: "Industrial Experts",
      answer: "We have a huge team that understands OTT technology, its functionality, its tools, and how it can enhance your business.",
      open: false
    },
    {
      question: "Seamless Communication",
      answer: "Rest assured of smooth communication flow; we keep our clients informed of every change and gladly welcome feedback.",
      open: false
    },
    {
      question: "Latest Technology",
      answer: "Employing cloud-based infrastructure, we optimize benefits for industrial projects, utilizing the most suitable tools for each tool.",
      open: false
    },

  ]);
  const toggleFAQ = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const categories = [
    { alt: "Healthcare", src: hhelth, title: "Healthcare" },
    { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
    { alt: "Social networking", src: networking, title: "Social networking" },
    { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
    { alt: "Travel", src: Travel, title: "Travel" },
    { alt: "Media", src: Media, title: "Media" },
    { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
    { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
  ];
  const reviews = [
    {
      rating: 5.0,
      reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
      author: "Founder & CEO, Larrea Wealth Management",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
      author: "Co-Founder & CEO, FLOHOM",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
      author: "Founder & CEO, FilmUp",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
      author: "Founder, Level7 Legal",
      verified: "Verified Review"
    }
  ];
  return (
    <div>
      <Header />
      <ScrollButton />
      <div className="w-full max-w-[1320px] h-auto bg-white mx-auto mt-12 rounded-[15px] p-6">
      <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
          <div className="text-center lg:text-left">
            <h1 className="text-3xl md:text-4xl lg:text-[45px] font-semibold">
              <span className="text-[#0652dd]">Flutter App Development</span><br /> Company
            </h1>
            <p className="text-sm md:text-base lg:text-[19px] mt-4">
              We quickly make Flutter apps for Android and iOS using one set of<br />
              code. Our goal is to make solutions your customers love and help<br />
              your business.
            </p>
            <ul className="text-sm md:text-lg mt-4 space-y-2">
              <li>App Migration</li>
              <li>Flutter App Consulting</li>
              <li>Flutter Team Augmentation</li>
              <li>Flutter Multi-Platform App Development</li>
              <li>Support & Maintenance</li>
            </ul>

            <button className="mt-6 text-sm bg-[#0652dd] border border-[#0652dd] rounded-full text-white h-10 transition-all duration-200 w-[220px] mx-auto lg:mx-0"><b>TALK TO OUR DEVELOPERS</b></button>
          </div>
          <img alt='FlutterAppDevelopment' className="h-auto w-full max-w-[504px] mx-auto lg:mx-0" src={FlutterAppDevelopment} />
        </div>
      </div>

      <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-[150px] mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
        <div className="flex items-center gap-[0.5rem]">
          <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
          <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
        </div>
        <div className="cont-no">
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
          {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
          <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
          <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
          <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
          <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
          <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
          {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
          <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
          <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
          <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
          <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
          {/* <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" /> */}
          <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
        </div>
      </div>
      <div className="w-full max-w-[1320px] h-auto bg-white mx-auto rounded-[15px] p-6">
        <div className=" flex flex-col lg:flex-row justify-center gap-10 lg:gap-20 items-center">
          <div className="text-center lg:text-left max-w-lg">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold">
              Leading <span className="text-[#0652dd]">Flutter App Development</span> Company in India
            </h1>
            <p className="text-base md:text-lg mt-4">
              BitFront is a top web and <span className="text-[#0652dd] font-semibold ">mobile app development company</span>
              specializing in cutting-edge app development services with Flutter. Flutter is a free tool for creating
              apps for both iOS and Android. With Flutter, we can build powerful, feature-rich apps for mobile, web,
              and desktop, all from one set of code. We use a comprehensive approach to create top-notch web and mobile
              apps ready for business. This means fast launches and updates, so our clients can keep up with the changing market.
            </p>
            <p className="text-base md:text-lg mt-6">
              Our skilled <b>Flutter app developers</b> use a mobile cross-platform approach, using the power of a dependable
              user interface framework. This allows us to build high-quality native interfaces for multiple platforms quickly.
              We take a result-oriented approach to app development and are committed to delivering projects on time.
            </p>
          </div>
          <img alt="Leading Flutter" className="w-full max-w-[470px] h-auto mt-6 lg:mt-0" src={LeadingFlutterAppDevelopment} />
        </div>
      </div>

      <div>
        <div className="w-full bg-white mt-10">
          <div className="flex flex-col lg:flex-row justify-center gap-10 lg:gap-16 ">
            <div className="text-center lg:text-left max-w-2xl">
              <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold">
                iOS App Development <span className="text-[#0652dd]">Services We Offer</span>
              </h1>
              <p className="text-base md:text-lg mt-4">
                At BitFront, our iPhone and iPad app developers know how to make great
                apps that leave a lasting impression and turn new users into paying
                customers. Regardless of your industry, our top-notch iOS app
                development services can help you attract a high return on investment.
              </p>
              <p className="text-base md:text-lg mt-4">
                BitFront offers end-to-end iOS app development services, including design,
                development, testing, support, and maintenance. Our expert team can take
                on any challenge, from designing an elegant user interface and experience,
                providing high-end coding solutions to completing the full cycle of
                developing iOS apps. Choosing iOS application development means opting
                for secure and scalable digital solutions. Our iOS software developers
                specialize in crafting applications that move your business towards the
                future.
              </p>
              <button className="mt-6 w-36 h-10 hover:bg-white hover:text-[#0652dd] bg-[#0652dd] text-white rounded-full border border-blue-800 cursor-pointer transition duration-200">
                <b>GET ESTIMATE</b>
              </button>
            </div>

            <div className="flex flex-col gap-6 mt-10 lg:mt-0">
              <div className="w-full max-w-lg bg-white rounded-tl-[30px] rounded-br-[30px] p-4 border">
                <div className="flex items-center gap-2 mb-4">
                  <img alt='iOS App Consultation' className="w-10 h-10" src={iOSAppDevelopmentConsultation} />
                  <h4 className="text-lg font-semibold">iOS App Development Consultation</h4>
                </div>
                <p className="text-sm md:text-base pl-2">
                  Flutter is popular for quick market entry. At BitFront, we assist
                  startups and enterprises in understanding Flutter's potential from
                  day one, laying the groundwork for success across multiple platforms.
                </p>
              </div>

              <div className="w-full max-w-lg bg-white rounded-tl-[30px] rounded-br-[30px] p-4 border">
                <div className="flex items-center gap-2 mb-4">
                  <img alt='iOS UI/UX Design' className="w-10 h-10" src={iOSUIUXDesign} />
                  <h4 className="text-lg font-semibold">iOS UI/UX Design</h4>
                </div>
                <p className="text-sm md:text-base pl-2">
                  Our Flutter app designers excel at creating apps that work
                  smoothly on different platforms, feeling just like native apps. They
                  use one codebase to design near-native experiences.
                </p>
              </div>

              <div className="w-full max-w-lg bg-white rounded-tl-[30px] rounded-br-[30px] p-4 border">
                <div className="flex items-center gap-2 mb-4">
                  <img alt='Custom iOS App Development' className="w-10 h-10" src={CustomiOSAppDevelopment} />
                  <h4 className="text-lg font-semibold">Custom iOS App Development</h4>
                </div>
                <p className="text-sm md:text-base pl-2">
                  Our Flutter apps look and feel almost like native ones. They're
                  strong, scalable, and work well on phones, wearables, and
                  computers. We keep your brand in focus from start to finish.
                </p>
              </div>

              <div className="w-full max-w-lg bg-white rounded-tl-[30px] rounded-br-[30px] p-4 border">
                <div className="flex items-center gap-2 mb-4">
                  <img alt='Custom iOS App Development' className="w-10 h-10" src={iOSAppDevelopment} />
                  <h4 className="text-lg font-semibold">Custom iOS App Development</h4>
                </div>
                <p className="text-sm md:text-base pl-2">
                  We're a Flutter app development company known for creating
                  secure and smooth experiences. No matter where your app runs,
                  we fix any problems and provide a seamless solution for your users.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <br />

      <h1 className="text-5xl text-center mt-15">Our <span className="text-[#0652dd]"> Multi-industry</span> Experience</h1>
      <br />
      <p className="text-lg text-center">
        BitFront has worked with a range of businesses, no matter their industry or size, transitioning to digital with iOS apps. We help pick the right tech, make<br /> user-friendly apps, and smoothly fit them into your systems.
      </p>
      <div className="flex flex-wrap justify-center items-center gap-7">
        {categories.map((category, index) => (
          <div
            key={index}
            className="hover:shadow-custom transition-all duration-200 w-full sm:w-[320px] h-[250px] bg-white rounded-[20px] mt-[20px] border-[1px]"
          >
            <div className="py-10 flex justify-center">
              <img alt={category.alt} src={category.src} width="60" height="60" />
            </div>
            <h4 className="text-center">{category.title}</h4>
          </div>
        ))}
      </div>

      <div className="mt-12 px-4 md:px-[30px] py-8 md:p-[30px_280px] w-full max-w-screen-xl h-auto bg-white mx-auto">
        <div className="w-full h-auto bg-[#fafafa] p-5">
          <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start">
            BitFront Reviews 5.0
            <span className="text-[#e62415] flex items-center">
              <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
            </span>
          </p>
          <div className="flex flex-col md:flex-row justify-center items-center gap-7">
            {reviews.map((review, index) => (
              <div key={index} className="w-full md:w-72 h-auto bg-white rounded-lg p-6 hover:shadow-lg">
                <p className="text-lg flex gap-2 items-center">
                  {review.rating}
                  <span className="text-[#e62415] flex items-center">
                    <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                  </span>
                </p>
                <p className="text-base pt-4">
                  "{review.reviewText}"
                  <br /><br />
                  {review.author}
                  <br /><br />
                  {review.verified}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>


      <h1 className="text-4xl text-center mt-12 font-bold"><span className="text-[#0652dd]">Flutter App Development</span> Process
      </h1>
      <br />
      <p className="text-center">
        Our Flutter app development ensures your success from day one. Partner with BitFront for more than just development – you get a CTO working as part of<br /> your team.
      </p>
      <br />
      <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
        </div>
      </div>

      <div className="bg-blue-700 w-3/4 h-auto mx-auto text-white text-center p-5 rounded-lg mt-10">
        <h3 className="text-center text-3xl font-bold">How much does it cost to make a Flutter App?</h3>
        <p className="text-lg">The average Flutter app development cost ranges from $15,000 to $2,00,000 according to the project's scope & complexity, developers’ experience,<br />
          and various other factors.</p>
        <div>
          <button onClick={handleClickContactus} className="w-[140px] h-[42px] text-[#0652dd] bg-[#fff] rounded-[25px] border-[1px] border-[#0652dd] cursor-pointer mt-[20px]"><b>Contact Us</b></button>
        </div>
      </div>

      <h1 className="text-center text-4xl mt-12 font-bold">
        <span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
      <div className="flex flex-wrap justify-center gap-9 mt-11 ">
        <div>
          <h1 className="text-3xl font-bold">
            <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
            <span className="text-[#0652dd]">Digital Success Works</span>
          </h1>
          <p className="text-lg">
            With a proven track record in React Native app development,<br />
            we're the perfect fit for your project. Here's why:
          </p>
          <br />
          <h5 className="text-lg flex items-center gap-2">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Global quality standards.</h5>
          <br />
          <h5 className="text-lg flex items-center gap-2">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Monthly/yearly hiring.</h5>
          <br />
          <h5 className="text-lg flex items-center gap-2">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Flexible office hours depend on the timezone.</h5>
          <br />
          <h5 className="text-lg flex items-center gap-2  ">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Daily/weekly/monthly reporting via email.</h5>
        </div>
        <div>

          <div className="App">

            <div className="faqs">
              {faqs.map((faq, index) => (
                <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-6 py-[30px]">
        <h2 className="lg:text-[40px] text-[30px] font-bold lg:leading-[59px] leading-[44px] text-center text-[#192734]">Looking for the best Mobile App Development Services for your<br /> business?</h2>
        <p className="text-center text-base leading-[30px] text-[#4c5a67] font-normal">We help top brands deliver quality products. Share your new business needs, and let us make it a success.</p>
        <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
          <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
          <div className="w-[344px] hidden md:block absolute top-[83px] right-[134px]">
            <p className="text-lg">
              Re-building Banksathi - A finance app making it easy for financial experts to make money.
            </p>
            <div className="inline-row">
              <div>
                <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">
                  4.4
                  <p className="text-[#DE5B48] text-[10px] flex gap-1">
                    <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                  </p>
                </span>
                <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
              </div>
              <div>
                <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
                <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
              </div>
            </div>
            <h1 className="my-3">1 Million+ downloads</h1>
            <div className="flex gap-4">
              <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
                <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" />
                <p><b>View Case Study</b></p>
              </button>
              <img className="w-[124px] h-[36px] cursor-pointer" alt="img" src="/images2/41p.jpg" />
            </div>
          </div>

          <div>
            <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
            <p className="my-2 text-base leading-[30px] text-[#4c5a67] font-normal">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
              in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
              scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
          </div>
          <div className="flex items-center gap-4">
            <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
            <div>
              <p>Corey Smith </p>
              <p>CEO, Emerge</p>
            </div>
          </div>
        </div>

        <div className="justify-center flex pt-5">
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Flutter
