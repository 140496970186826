import './Blog.css';
import React from 'react';
import "react-phone-input-2/lib/bootstrap.css";
import { CiSearch } from "react-icons/ci";
import Header from "../../components/layout/Header";
import Footer from '../Footer';
import Designmistakesavoid from '../../assets/images/Designmistakesavoid.png'
import FinTechapplication from '../../assets/images/FinTechapplication.png';
import imageright from '../../assets/images/imageright.png';
import Questionsaskdevelopment from '../../assets/images/Questionsaskdevelopment.png';
import HealthcareSoftwareDevelopment from '../../assets/images/HealthcareSoftwareDevelopment.png';
import designfortrends from '../../assets/images/designfortrends.png';
import Mobileappsecurity from '../../assets/images/Mobileappsecurity.png';
import StepstepGuide from '../../assets/images/StepstepGuide.png';
import ProgressiveWebApp from '../../assets/images/ProgressiveWebApp.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const Blog = () => {
  const posts = [
    {
      title: "What Is a Warehouse Management System?",
      content: "Healthcare software is essential nowadays and a main component of current medicine. Various causes determine such growth. First, it ensures",
      buttonText: "Healthcare Software Development",
      imgSrc: HealthcareSoftwareDevelopment,
      imgAlt: 'Healthcare Software Development',
    },
    {
      title: "How to Get the App Featured on the Google Play Store?",
      content: "Healthcare software is essential nowadays and a main component of current medicine. Various causes determine such growth. First, it ensures",
      buttonText: "Healthcare Software Development",
      imgSrc: designfortrends,
      imgAlt: 'Design for Trends',
    },
    {
      title: "How to Get Your App Featured on the Apple App Store?",
      content: "Healthcare software is essential nowadays and a main component of current medicine. Various causes determine such growth. First, it ensures",
      buttonText: "Healthcare Software Development",
      imgSrc: Mobileappsecurity,
      imgAlt: 'Mobile App Security',
    },
    {
      title: "Top 10 Artificial Intelligence Development Companies in India",
      content: "Healthcare software is essential nowadays and a main component of current medicine. Various causes determine such growth. First, it ensures",
      buttonText: "Healthcare Software Development",
      imgSrc: StepstepGuide,
      imgAlt: 'Step by Step Guide',
    },
    {
      title: "Top 10 Most Common App Design Mistakes You Should Avoid",
      content: "Healthcare software is essential nowadays and a main component of current medicine. Various causes determine such growth. First, it ensures",
      buttonText: "Healthcare Software Development",
      imgSrc: ProgressiveWebApp,
      imgAlt: 'Progressive Web App',
    },
  ];
  // const categories = [
  //   [
  //     { name: "App Development", count: 13 },
  //     { name: "Startup Ideas", count: 9 },
  //     { name: "Healthcare Industry", count: 7 },
  //     { name: "App Launch Checklist", count: 4 },
  //   ],
  //   [
  //     { name: "Top Companies", count: 4 },
  //     { name: "UI and UX Design", count: 4 },
  //     { name: "App Development Cost", count: 3 },
  //     { name: "Healthcare Technology Trends", count: 3 },
  //   ],
  //   [
  //     { name: "Agile Methodology", count: 3 },
  //     { name: "Startup Company", count: 3 },
  //     { name: "ChatGPT and OpenAI", count: 3 },
  //     { name: "Hire Developers for Startup", count: 2 },
  //   ],
  // ];
  return (
    <div>
      <Header />
      <ScrollButton />
      <div className="relative font-sans">
        <input
          type="text"
          placeholder="Search here.."
          className="text-black xl:w-[725px] lg:w-[700px] md:w-[600px] sm:w-[300px] w-[250px] h-[40px] border-2 border-[#4a89bf] lg:ml-[15%] sm:ml-[10%] ml-[5%] rounded-[30px] mb-[0.5%] shadow-[rgb(255,255,255)] pt-[15.5px] pb-[14.35px] pl-[20px] mt-[15px]" />
        <CiSearch className="absolute top-[38px] xl:right-[48%] lg:right-[18%] md:right-[15%] sm:right-[48%]  right-[25%] text-[150%] translate-y-[-60%] text-[#4a89bf]" />
      </div>
      <div className="max-w-[1500px] h-auto bg-white rounded-2xl m-auto md:mt-[80px] mt-[20px] ">
        <div className=" grid lg:grid-cols-2 md:grid-cols-1 justify-center gap-8 mt-8 px-4 md:px-8 lg:px-16">
          <div>
            <img
              alt='Artificial'
              className="xl:w-[732px] xl:h-[395px] md:w-[500px] rounded-[15px]"
              src="https://miro.medium.com/v2/resize:fit:1200/1*tNIBuhrmbtTYxPIKv5238g.jpeg" />
            <h1 className='mt-[20px] text-[20px] font-semibold'>Top 10 Artificial Intelligence Development Companies in India</h1>
            <p>Presently, India is trending fast as the world’s next AI development laboratory. Thanks
              to its plentiful talent, clear focus on <span className="text-[#0652dd]">...Read More</span></p>
            <p><b>Product & design - 6 min read</b></p>
          </div>
          <div className="space-y-8">
            <div className='max-w-[500px]'>
              <div className="grid sm:grid-cols-2 grid-cols-1 items-start gap-4">
                <img alt='Design Mistakes' className="w-48 h-28 rounded-lg" src={Designmistakesavoid} />
                <div>
                  <h1 className="text-lg">Top 10 Most Common App Design Mistakes You Should Avoid</h1>
                  <button className="mt-2 w-full md:w-auto px-4 py-2 bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full hover:text-[#0840a7]">
                    <b>App Design Mistakes</b>
                  </button>
                </div>
              </div>
            </div>
            <div className='max-w-[500px]'>
              <div className="grid sm:grid-cols-2 grid-cols-1 items-start gap-4">
                <img alt='FinTech App' className="w-48 h-28 rounded-lg" src={FinTechapplication} />
                <div>
                  <h1 className="text-lg">Fintech App Development: Guide with Challenges & Costs</h1>
                  <button className="mt-2 w-full md:w-auto px-4 py-2 bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full hover:text-[#0840a7]">
                    <b>FinTech App Develop Cost</b>
                  </button>
                </div>
              </div>
            </div>
            <div className='max-w-[500px]'>
              <div className="grid sm:grid-cols-2 grid-cols-1 items-start gap-4">
                <img alt='Software Development' className="w-48 h-28 rounded-lg" src={imageright} />
                <div>
                  <h1 className="text-lg">How to Choose the Right Software Development Company?</h1>
                  <button className="mt-2 w-full md:w-auto px-4 py-2 bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full hover:text-[#0840a7]">
                    <b>App Development</b>
                  </button>
                </div>
              </div>
            </div>
            <div className='max-w-[500px]'>
              <div className="grid sm:grid-cols-2 grid-cols-1 items-start gap-4">
                <img alt='Design Mistakes' className="w-48 h-28 rounded-lg" src={Questionsaskdevelopment} />
                <div>
                  <h1 className="text-lg">22 Questions to ask when
                    hiring a Mobile App
                    Development Agency</h1>
                  <button className="mt-2 w-full md:w-auto px-4 py-2 bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full hover:text-[#0840a7]">
                    <b>App Development</b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-4 mt-12 px-4 md:px-0">
        <h1 className="text-2xl font-bold">All Posts</h1>
        <div className="w-full max-w-[800px] h-[1.2px] bg-[#a9a9a9]"></div>
        <div className="w-full max-w-[800px]">
          {posts.map((post, index) => (
            <div key={index} className="flex flex-col md:flex-row items-center mt-5 gap-4">
              <div className="flex-1">
                <p className="font-semibold text-lg">{post.title}</p>
                <p>{post.content}<span className="text-[#0652dd]">...Read More</span></p>
                <button className="mt-2 w-full md:w-[280px] h-[40px] text-[15px] bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-[20px] cursor-pointer">
                  <b>{post.buttonText}</b>
                </button>
              </div>
              <img alt={post.imgAlt} className="w-full h-auto md:w-[340px] md:h-[183px] rounded-[20px]" src={post.imgSrc} />
            </div>
          ))}
        </div>
      </div>
      <h2 className="text-center lg:mb-[3%] mt-[50px] text-2xl font-semibold">Top Categories</h2>
      <div className="mt-[40px] mb-[10px]">
        <div className="flex flex-wrap justify-center items-center gap-8 mt-4">
          <button className="w-auto px-4 py-2 mb-[10px] bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full">
            <b>App Development (13)</b>
          </button>
          <button className="w-auto px-4 py-2 mb-[10px] bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full">
            <b>Startup Ideas (9)</b>
          </button>
          <button className="w-auto px-4 py-2 mb-[10px] bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full">
            <b>Healthcare Industry (7)</b>
          </button>
          <button className="w-auto px-4 py-2 mb-[10px] bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full">
            <b>App Launch Checklist (4)</b>
          </button>
        </div>

        <div className="flex flex-wrap justify-center items-center gap-8 mt-4">
          <button className="w-auto px-4 py-2 mb-[10px] bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full">
            <b>Top Companies (4)</b>
          </button>
          <button className="w-auto px-4 py-2 mb-[10px] bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full">
            <b>UI and UX Design (4)</b>
          </button>
          <button className="w-auto px-4 py-2 mb-[10px] bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full">
            <b>App Development Cost (3)</b>
          </button>
          <button className="w-auto px-4 py-2 mb-[10px] bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full">
            <b>Healthcare Technology Trends (3)</b>
          </button>
        </div>

        <div className="flex flex-wrap justify-center items-center gap-8 mt-4">
          <button className="w-auto px-4 py-2 mb-[10px] bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full">
            <b>Agile Methodology (3)</b>
          </button>
          <button className="w-auto px-4 py-2 mb-[10px] bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full">
            <b>Startup Company (3)</b>
          </button>
          <button className="w-auto px-4 py-2 mb-[10px] bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full">
            <b>ChatGPT and OpenAI (3)</b>
          </button>
          <button className="w-auto px-4 py-2 mb-[10px] bg-[#B0CBFD40] text-[#0652dd] border border-[#0652dd] rounded-full">
            <b>Hire Developers for Startup (2)</b>
          </button>
        </div>

      </div>
      <Footer />
    </div>
  )
}

export default Blog
