import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import About from './pages/Company/About';
import Home from './pages/Home';
import Career from './pages/Company/Career';
import Blog from './pages/Company/Blog';
import Mobile from './pages/Services/Mobile';
import Contactus from './pages/Company/Contactus';
import AndroidAppDevelopment from './pages/Services/AndroidAppDevelopment';
import Phone from './pages/Services/Phone';
import Flutter from './pages/Services/Flutter';
import Reactnativedevelopment from './pages/Services/Reactnativedevelopment';
import Webappdevelopment from './pages/Services/Webappdevelopment';
import Cloudappdevelopment from './pages/Services/Cloudappdevelopment';
import Webdesigningdevelopment from './pages/Services/Webdesigningdevelopment';
import Shopifyappdevelopment from './pages/Services/Shopifyappdevelopment';
import UIUXdesign from './pages/Services/UIUXdesign';
import Chatgptdevelopment from './pages/Services/Chatgptdevelopment';
import BitfrontAI from './components/layout/BitfrontAI';
import GenerativeAi from './pages/Services/GenerativeAi';
import DevOpsConsulting from './pages/Services/DevOpsConsulting';
import Machinelearning from './pages/Services/Machinelearning';
import OTTAppDevelopment from './pages/Services/OTTAppDevelopment';
import Blockchaindevelopment from './pages/Services/Blockchaindevelopment';
import ARVRappdevelopment from './pages/Services/ARVRappdevelopment';
import DeepLearningDevelopment from './pages/Services/DeepLearningDevelopment';
import AiDevelopment from './pages/Services/AiDevelopment';
import HybridAppDevelopment from './pages/Services/HybridAppDevelopment';
import NativeAppDevelopment from './pages/Services/NativeAppDevelopment';
import Healthcare from './pages/Industries/Healthcare';
import Fintech from './pages/Industries/Fintech';
import Ecommerce from './pages/Industries/Ecommerce';
import AutotechEv from './pages/Industries/AutotechEv';
import EdtechElearning from './pages/Industries/EdtechElearning';
import TravelTech from './pages/Industries/TravelTech';
import EsportGameApp from './pages/Industries/EsportGameApp';
import Logistic from './pages/Industries/Logistic';
import SocialNetworking from './pages/Industries/SocialNetworking';
import Entertainment from './pages/Industries/Entertainment';
import RealEstate from './pages/Industries/RealEstate';
import CustomizableApp from './pages/Solutions/CustomizableApp';
import OnDemandAppDevelopment from './pages/Solutions/OnDemandAppDevelopment';
import ArchitectureService from './pages/Solutions/ArchitectureService';
import StartupAppDevelopment from './pages/Solutions/StartupAppDevelopment';
import EnterpriseSoftwareDevelopment from './pages/Solutions/EnterpriseSoftwareDevelopment';

import './App.css';
import ScrollToTop from './components/Scrollissu/scrollTop';
import Photos from './pages/photos';
function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1800);

    return () => clearTimeout(timer);
  }, []);

  const letters = "BITFRONT INFOTECH".split("");
  return (
    <Router>
      <ScrollToTop />
      {isLoading ? (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
          <div
            className="animate-spin rounded-full h-20 w-20 flex justify-center items-center border-t-4 border-b-4 border-blue-500">
            <svg
              className="animate-spin h-16 w-16"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <circle
                className="opacity-25"
                strokeWidth="4"
                cx="12"
                cy="12"
                r="10"
              />
              <path
                className="opacity-75"
                fill="blue"
                d="M12 2a10 10 0 0 1 0 20 10 10 0 0 1 0-20zm0 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
              />
            </svg>
          </div>
          <h1 className="mt-8 xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] font-bold tracking-wide flex space-x-1">
            {letters.map((letter, index) => (
              <span
                key={index}
                className="animate-fadeIn  "
                style={{ animationDelay: `${index * 0.1}s` }}
              >
                {letter}
              </span>
            ))}
          </h1>
          <p className="mt-4 text-lg text-blue-400 loading-text">LOADING...</p>
        </div>
      ) : (
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/career" element={<Career />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/Mobile" element={<Mobile />} />
            <Route path="/Contact-us" element={<Contactus />} />
            <Route path="/Android-App-Development" element={<AndroidAppDevelopment />} />
            <Route path="/Phone" element={<Phone />} />
            <Route path="/Flutter" element={<Flutter />} />
            <Route path="/React-native-development" element={<Reactnativedevelopment />} />
            <Route path="/Web-app-development" element={<Webappdevelopment />} />
            <Route path="/Cloud-app-development" element={<Cloudappdevelopment />} />
            <Route path="/Web-designing-development" element={<Webdesigningdevelopment />} />
            <Route path="/Shopify-app-development" element={<Shopifyappdevelopment />} />
            <Route path="/UI-UX-design" element={<UIUXdesign />} />
            <Route path="/Chatgpt-development" element={<Chatgptdevelopment />} />
            <Route path="/Generative-Ai" element={<GenerativeAi />} />
            <Route path="/Dev-Ops-Consulting" element={<DevOpsConsulting />} />
            <Route path="/Machine-learning" element={<Machinelearning />} />
            <Route path="/OTT-App-Development" element={<OTTAppDevelopment />} />
            <Route path="/Block-chain-development" element={<Blockchaindevelopment />} />
            <Route path="/AR-VR-app-development" element={<ARVRappdevelopment />} />
            <Route path="/Deep-Learning-Development" element={<DeepLearningDevelopment />} />
            <Route path="/Ai-Development" element={<AiDevelopment />} />
            <Route path="/Hybrid-App-Development" element={<HybridAppDevelopment />} />
            <Route path="/Native-App-Development" element={<NativeAppDevelopment />} />
            <Route path="/Healthcare" element={<Healthcare />} />
            <Route path="/Bitfront-AI" element={<BitfrontAI />} />
            <Route path="/Fintech" element={<Fintech />} />
            <Route path="/Ecommerce" element={<Ecommerce />} />
            <Route path="/Auto-tech-Ev" element={<AutotechEv />} />
            <Route path="/EdtechElearning" element={<EdtechElearning />} />
            <Route path="/Travel-Tech" element={<TravelTech />} />
            <Route path="/Esport-Game-App" element={<EsportGameApp />} />
            <Route path="/Logistic" element={<Logistic />} />
            <Route path="/Social-Net-working" element={<SocialNetworking />} />
            <Route path="/Entertainment" element={<Entertainment />} />
            <Route path="/Real-E-state" element={<RealEstate />} />
            <Route path="/Customizable-App" element={<CustomizableApp />} />
            <Route path="/On-Demand-App-Development" element={<OnDemandAppDevelopment />} />
            <Route path="/Architecture-Service" element={<ArchitectureService />} />
            <Route path="/Startup-App-Development" element={<StartupAppDevelopment />} />
            <Route path="/Enterprise-Software-Development" element={<EnterpriseSoftwareDevelopment />} />
            <Route path="career/photos" element={<Photos />} />
          </Routes>

        </div>
      )}
    </Router>
  );
}

export default App;
