import React, { useState } from 'react';
import Header from '../../components/layout/Header'
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import 'animate.css';
import TravelAppDevelopment from '../../assets/images/TravelAppDevelopment.png';
import travelplanningapps from '../../assets/images/travelplanningapps.png';
import hotelbookingapps from '../../assets/images/hotelbookingapps.png';
import ticketbookingsolution from '../../assets/images/ticketbookingsolution.png';
import vehiclebookingapps from '../../assets/images/vehiclebookingapps.png';
import TravelDevelopment from '../../assets/images/TravelDevelopment.png';
import EVTrip from '../../assets/images/EVTrip.png';
import touristapp from '../../assets/images/touristapp.png';
import travelagentapp from '../../assets/images/travelagentapp.png';
import tourbookingapp from '../../assets/images/tourbookingapp.png';
import travelguideapp from '../../assets/images/travelguideapp.png';
import holidayplannerapp from '../../assets/images/holidayplannerapp.png';
import TravelMobileAppFeatures from '../../assets/images/TravelMobileAppFeatures.png';
import tripplanning from '../../assets/images/tripplanning.png';
import travelmap from '../../assets/images/travelmap.png';
import traveltracking from '../../assets/images/traveltracking.png';
import travelchecklist from '../../assets/images/travelchecklist.png';
import touristguide from '../../assets/images/touristguide.png';
import tourbooking from '../../assets/images/tourbooking.png';
import vehiclebooking from '../../assets/images/vehiclebooking.png';
import traveldestinations from '../../assets/images/traveldestinations.png';
import hotelbooking from '../../assets/images/hotelbooking.png';
import travelagentfeatures from '../../assets/images/travelagentfeatures.png';
import triporganiser from '../../assets/images/triporganiser.png';
import iconotv from '../../assets/images/iconotv.png';
import icongk from '../../assets/images/icongk.png';
import confident from '../../assets/images/confident.png';
import meet from '../../assets/images/meet.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const TravelTech = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "What is travel app development?",
            answer:
                "Travel mobile app development is a process of creating a mobile application for travelers. It involves designing, developing and deploying the mobile app on multiple platforms like iOS, Android and Windows Phone. A travel app is a product developed to help you with your trip planning",
            open: false
        },
        {
            question: "Do I need to build an app for my travel business?",
            answer: "If you're aiming to grow your travel business and connect with more people, creating a travel app is essential. Additionally, having an app can streamline your travel operations and enhance the booking experience for users.",
            open: false
        },
        {
            question:
                "Which mobile platform is better for a travel app development project?",
            answer: "It depends entirely on your requirement and end goal. If your user base is across the regions with more Android users, go with Android, and if your user base is limited to the regions with more iPhone users, iOS is what you should choose. You can also consider cross-platform development to cater to both audiences simultaneously.",
            open: false
        },
        {
            question:
                "What features should be included in the travel mobile app development process?",
            answer: "The exact time to create a mobile application depends on its features, the screen sizes, and the frameworks used. Generally, it takes 2 months to around 4–5 months depending on developers, functionalities of the app, platform, and much more.",
            open: false
        },
        {
            question:
                "What is the cost of developing a travel app?",
            answer: "The average cost to develop a travel application is $5,000 to $30,000 & more. This cost depends on many factors like complexity, features & functionalities, development platform, approach, developer location & experience, etc.",
            open: false
        },
        {
            question:
                "Is tourism mobile app development a good investment option?",
            answer: "Travel used to be dominated by agents who sometimes took advantage. But now, mobile apps make planning trips easier. Every travel company, big or small, needs an app to offer extra services and stay connected with users. Whether you're a big agency or a local operator, having an app helps customers find you easily.",
            open: false
        },
        {
            question:
                "Is it important to integrate AI-driven features in a travel app?",
            answer: "Yes, Artificial Intelligence can enhance the user experience by providing personalized recommendations, finding exclusive deals and discounts, assessing travel risks, trip planning, and offering real-time assistance. It leads to a more dynamic and attentive user experience.",
            open: false
        }
    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">Travel App</span> Development Company</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            Promote customer satisfaction with our cutting-edge, adaptable, and reliable mobile
                            app solution tailored for seamless travel experiences.
                        </p>
                        <li className="mt-2">Travel Planner and Guide</li>
                        <li className="mt-2">GPS Based Features</li>
                        <li className="mt-2">Easy Profile Creation</li>
                        <li className="mt-2">Filtered Search Options</li>
                        <li className="mt-2">Secure Payment Processing</li>
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[280px] mt-10 h-[40px]">
                            TALK TO OUR DEVELOPERS
                        </button>
                    </div>
                    <img alt='TravelAppDevelopment' className="w-full md:w-[596px] h-auto md:h-[450px] mt-8 md:mt-0 mx-auto" src={TravelAppDevelopment} />
                </div>
            </div>


            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-[150px] mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                </div>
            </div>

            <div className="flex flex-col sm:flex-row justify-center sm:gap-[5rem]">
                <div className="mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#339882] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='travelplanningapps' src={travelplanningapps} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Travel planning apps</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        At BitFront, our focus lies in crafting travel planning and organization applications,
                        enhanced with features such as blogs, videos, multi-language support, event details,
                        and beyond. Our mission is straightforward: to assist travelers in effortlessly creating their itineraries.
                    </p>
                </div>

                <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#ffacf5] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='hotelbookingapps' src={hotelbookingapps} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Hotel booking apps</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        As a hotel booking app development company, we excel in creating apps for both merchant and aggregate models.
                        Whether you're a hotel seeking digital solutions or an entrepreneur aiming to build the next Expedia, we have
                        the expertise you need.
                    </p>
                </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-center sm:gap-[5rem]">
                <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#ffabab] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='ticketbookingsolution' src={ticketbookingsolution} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Ticket booking solutions</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        At BitFront, we specialize in streamlining the ticket booking process by incorporating
                        features such as real-time availability and price comparison, currency conversion,
                        multiple payment options, and travel information download and sharing, ensuring a hassle-free
                        experience for every traveler.
                    </p>
                </div>
                <div className=" mb-[20px] mt-[40px] w-full sm:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#877728] border-[2px]">
                    <div className="flex items-center gap-1">
                        <img alt='vehiclebookingapps' src={vehiclebookingapps} />
                        <h4 className="text-[18px] sm:text-[20px] font-bold">Vehicle booking apps</h4>
                    </div>
                    <p className="text-[16px] sm:text-[17px] pl-2 mt-2">
                        We've developed over 5 vehicle booking apps with cutting-edge features such as AI-driven driver-traveler
                        connections, multiple route options, secure feedback systems, and more. Our unique selling point is our
                        commitment to delivering solutions that operate seamlessly across all network conditions.
                    </p>
                </div>
            </div>

            <h1 className="xl:text-[35px] lg:text-[27px] md:text-[20px] text-[16px] font-semibold text-center mt-10"><span className="text-[#0652dd]">Travel App</span> Development Solutions</h1>
            <p className="text-[18px] font-medium text-center m-auto w-full xl:w-[1100px]">From small travel agencies to international players in tourism and hospitality, our advanced travel app development solutions serve all. We offer highly
                functional, user-friendly designs customized to suit various business models.</p>

            <div className="flex flex-wrap justify-center items-center gap-8 lg:gap-[5rem] mt-[40px]">
                <img alt="TravelDevelopment" className="w-full lg:w-[624px] h-auto lg:h-[387px]" src={TravelDevelopment} />

                <div className="w-full lg:w-auto">
                    <div className="mt-[20px] lg:mt-[40px] w-full lg:w-[550px] h-auto lg:h-[65px] border-[#d7e5a6] border-2 rounded-[10px]">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt="EVTrip" className="w-9 h-9" src={EVTrip} />
                            <h1 className="text-[20px] font-medium">Trip Planner App</h1>
                        </div>
                    </div>

                    <div className="mt-[20px] lg:mt-[40px] w-full lg:w-[550px] h-auto lg:h-[65px] border-[#afe5a6] border-2 rounded-[10px]">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt="touristapp" className="w-9 h-9" src={touristapp} />
                            <h1 className="text-[20px] font-medium">Tourist App/City Guide App</h1>
                        </div>
                    </div>

                    <div className="mt-[20px] lg:mt-[40px] w-full lg:w-[550px] h-auto lg:h-[65px] border-[#a6e5e3] border-2 rounded-[10px]">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt="travelagentapp" className="w-9 h-9" src={travelagentapp} />
                            <h1 className="text-[20px] font-medium">Travel Agent App</h1>
                        </div>
                    </div>

                    <div className="mt-[20px] lg:mt-[40px] w-full lg:w-[550px] h-auto lg:h-[65px] border-[#a6b7e5] border-2 rounded-[10px]">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt="tourbookingapp" className="w-9 h-9" src={tourbookingapp} />
                            <h1 className="text-[20px] font-medium">Tour Booking App</h1>
                        </div>
                    </div>

                    <div className="mt-[20px] lg:mt-[40px] w-full lg:w-[550px] h-auto lg:h-[65px] border-[#c0a6e5] border-2 rounded-[10px]">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt="travelguideapp" className="w-9 h-9" src={travelguideapp} />
                            <h1 className="text-[20px] font-medium">Travel Guide App</h1>
                        </div>
                    </div>

                    <div className="mt-[20px] lg:mt-[40px] w-full lg:w-[550px] h-auto lg:h-[65px] border-[#e5a6aa] border-2 rounded-[10px]">
                        <div className="flex p-[15px] gap-[2rem] items-center">
                            <img alt="holidayplannerapp" className="w-9 h-9" src={holidayplannerapp} />
                            <h1 className="text-[20px] font-medium">Holiday Planner App</h1>
                        </div>
                    </div>
                </div>
            </div>


            <h1 className=" mt-8 text-[38px] font-semibold text-center"><span className="text-[#0652dd]">Travel Mobile</span> App Features</h1>
            <p className="text-[18px] font-medium text-center m-auto xl:w-[940px]">Our dedicated travel app provides a complete business solution
                for your agency. Designed to enhance your customers' experience, it helps retain existing clients and attract new ones seeking
                top-notch travel services for seamless planning and execution.</p>
            <div className="flex flex-col lg:flex-row justify-center items-center gap-8 lg:gap-[5rem] mt-[60px]">
                <div>
                    <div className="mt-6 gap-[2rem] flex items-center">
                        <img alt="tripplanning" className="w-9 h-9" src={tripplanning} />
                        <h1 className="text-[18px] font-medium">Trip planning</h1>
                    </div>
                    <div className="mt-6 gap-[2rem] flex items-center">
                        <img alt="travelmap" className="w-9 h-9" src={travelmap} />
                        <h1 className="text-[18px] font-medium">Travel Map</h1>
                    </div>
                    <div className="mt-6 gap-[2rem] flex items-center">
                        <img alt="traveltracking" className="w-9 h-9" src={traveltracking} />
                        <h1 className="text-[18px] font-medium">Travel Tracking</h1>
                    </div>
                    <div className="mt-6 gap-[2rem] flex items-center">
                        <img alt="travelchecklist" className="w-9 h-9" src={travelchecklist} />
                        <h1 className="text-[18px] font-medium">Travel Checklist</h1>
                    </div>
                    <div className="mt-6 gap-[2rem] flex items-center">
                        <img alt="touristguide" className="w-9 h-9" src={touristguide} />
                        <h1 className="text-[18px] font-medium">Tourist Guide</h1>
                    </div>
                    <div className="mt-6 gap-[2rem] flex items-center">
                        <img alt="tourbooking" className="w-9 h-9" src={tourbooking} />
                        <h1 className="text-[18px] font-medium">Tour Booking</h1>
                    </div>
                </div>

                <img alt="Travel Mobile App Features" className="w-full lg:w-[406px] h-auto lg:h-[457px]" src={TravelMobileAppFeatures} />

                <div>
                    <div className="mt-6 gap-[2rem] flex items-center">
                        <img alt="vehiclebooking" className="w-9 h-9" src={vehiclebooking} />
                        <h1 className="text-[18px] font-medium">Vehicle Booking</h1>
                    </div>
                    <div className="mt-6 gap-[2rem] flex items-center">
                        <img alt="traveldestinations" className="w-9 h-9" src={traveldestinations} />
                        <h1 className="text-[18px] font-medium">Travel Destinations</h1>
                    </div>
                    <div className="mt-6 gap-[2rem] flex items-center">
                        <img alt="hotelbooking" className="w-9 h-9" src={hotelbooking} />
                        <h1 className="text-[18px] font-medium">Hotel Booking</h1>
                    </div>
                    <div className="mt-6 gap-[2rem] flex items-center">
                        <img alt="travelagentfeatures" className="w-9 h-9" src={travelagentfeatures} />
                        <h1 className="text-[18px] font-medium">Travel Agent Features</h1>
                    </div>
                    <div className="mt-6 gap-[2rem] flex items-center">
                        <img alt="triporganiser" className="w-9 h-9" src={triporganiser} />
                        <h1 className="text-[18px] font-medium">Trip Organizer</h1>
                    </div>
                </div>
            </div>


            <h1 className="font-bold xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Travel App</span> Development Process</h1>
            <p className="text-[18px] text-center mt-4">
                Every stage of our travel app development solutions is backed by a guarantee of innovation, positioning you as the industry leader.
            </p>
            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>

            <h1 className=" mt-10 text-center text-[38px] font-semibold"><span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex flex-col lg:flex-row justify-center items-center gap-8 lg:gap-[5rem] mt-[30px] px-4 lg:px-0">
                <div className="text-center lg:text-left">
                    <h1 className="text-2xl lg:text-3xl font-bold">
                        <span className="text-[#0652dd]">Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-base lg:text-lg mt-4">
                        With a proven track record in React Native app development,<br />
                        we're the perfect fit for your project. Here's why:
                    </p>
                </div>

                <div className="mt-6 lg:mt-0">
                    <div className="flex flex-col lg:flex-row gap-8 lg:gap-[5rem]">
                        <div className="w-full lg:w-[300px]">
                            <div className="mt-6 gap-[2rem] flex items-center">
                                <img alt="iconotv" src={iconotv} className="w-10 h-10" />
                                <h1 className="text-[16px] lg:text-[18px] font-medium">We're experts at providing unique mobility solutions to our clients.</h1>
                            </div>
                            <div className="mt-6 gap-[2rem] flex items-center">
                                <img alt="icongk" src={icongk} className="w-10 h-10" />
                                <h1 className="text-[16px] lg:text-[18px] font-medium">We have proficient expertise in delivering high-quality work.</h1>
                            </div>
                        </div>

                        <div className="w-full lg:w-[325px]">
                            <div className="mt-6 gap-[2rem] flex items-center">
                                <img alt="confident" src={confident} className="w-10 h-10" />
                                <h1 className="text-[16px] lg:text-[18px] font-medium">We give the 100% assurance of confidentiality and security of client's data.</h1>
                            </div>
                            <div className="mt-6 gap-[2rem] flex items-center">
                                <img alt="meet" src={meet} className="w-10 h-10" />
                                <h1 className="text-[16px] lg:text-[18px] font-medium">We offer innovative solutions to meet the latest travel requirements.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className=" mt-[40px] m-auto p-[40px] w-full xl:w-[1320px] h-auto xl:h-[147px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[25px] font-semibold text-[#fff]">Partner with us to experience advanced travel app development services to enhance your travel business.</h1>
            </div>

            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>

            <div className="container mx-auto px-6 py-[30px]">
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                </div>
            </div>
            <h1 className="faqsopara mt-[40px] font-semibold text-[25px]">FAQs</h1>
            <div className="App">

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default TravelTech
