import React, { useState, useEffect } from "react";
import { FaAngleDown } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";
import "react-phone-input-2/lib/bootstrap.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import './Header.css'
import { FaBars, FaTimes } from "react-icons/fa";
import buttonlogo from '../../assets/images/buttonlogo.svg';
export default function Header() {
    const navigate = useNavigate();
    const location = useLocation();

    const [isDarkMode, setIsDarkMode] = useState(() => {
        const darkModeSetting = localStorage.getItem("darkMode");
        return darkModeSetting === "true";
    });

    // console.log('isDarkMode:', isDarkMode);

    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
        localStorage.setItem("darkMode", isDarkMode);
    }, [isDarkMode]);

    useEffect(() => {
        if (location.pathname === "/bitfront-ai") {
            setIsDarkMode(true);
        } else {
            setIsDarkMode(false);
        }
    }, [location.pathname]);

    const handleClickiop = () => {
        setIsDarkMode(true);
        navigate('/bitfront-ai');
    };

    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    const menuItems = [
        {
            title: "COMPANY",
            items: [
                { label: "About Us", onClick: () => navigate("/about") },
                { label: "Career", onClick: () => navigate("/career") },
                { label: "Blog", onClick: () => navigate("/blog") },
                { label: "Contact us", onClick: () => navigate("/contact-us") },
            ],
        },
        {
            title: "SERVICES",
            items: [
                { label: "Mobile App Development", onClick: () => navigate("/mobile") },
                { label: "Android App Development", onClick: () => navigate("/android-app-development") },
                { label: "iOS App Development", onClick: () => navigate("/phone") },
                { label: "Flutter App Development", onClick: () => navigate("/flutter") },
                { label: "React Native App Development", onClick: () => navigate("/react-native-development") },
                { label: "Web App Development", onClick: () => navigate("/web-app-development") },
                { label: "Cloud App Development", onClick: () => navigate("/cloud-app-development") },
                { label: "Web Designing & Development", onClick: () => navigate("/web-designing-development") },
                { label: "Shopify App Development", onClick: () => navigate("/shopify-app-development") },
                { label: "UI/UX Design", onClick: () => navigate("/ui-ux-design") },
                { label: "ChatGPT Development", onClick: () => navigate("/chatgpt-development") },
            ],
            itemsRight: [
                { label: "Generative AI", onClick: () => navigate("/generative-ai") },
                { label: "DevOps Consulting", onClick: () => navigate("/dev-ops-consulting") },
                { label: "Machine Learning", onClick: () => navigate("/machine-learning") },
                { label: "OTT App Development", onClick: () => navigate("/ott-app-development") },
                { label: "Blockchain Development", onClick: () => navigate("/block-chain-development") },
                { label: "AR VR App Development", onClick: () => navigate("/ar-vr-app-development") },
                { label: "Deep Learning Development", onClick: () => navigate("/deep-learning-development") },
                { label: "AI Development", onClick: () => navigate("/ai-development") },
                { label: "Hybrid App Development", onClick: () => navigate("/hybrid-app-development") },
                { label: "Native App Development", onClick: () => navigate("/native-app-development") },
            ],
        },
        {
            title: "INDUSTRIES",
            items: [
                { label: "Healthcare", onClick: () => navigate("/healthcare") },
                { label: "FinTech", onClick: () => navigate("/fintech") },
                { label: "eCommerce", onClick: () => navigate("/ecommerce") },
                { label: "AutoTech-EV", onClick: () => navigate("/auto-tech-ev") },
                { label: "EdTech- eLearning", onClick: () => navigate("/edtechelearning") },
                { label: "TravelTech", onClick: () => navigate("/travel-tech") },
                { label: "Esport- Game App", onClick: () => navigate("/esport-game-app") },
                { label: "Logistic", onClick: () => navigate("/logistic") },
                { label: "Social Networking", onClick: () => navigate("/social-net-working") },
                { label: "Entertainment", onClick: () => navigate("/entertainment") },
                { label: "Real Estate", onClick: () => navigate("/real-e-state") },
            ],
        },
        {
            title: "SOLUTIONS",
            items: [
                { label: "Customizable App", onClick: () => navigate("/customizable-app") },
                { label: "On-Demand App Development", onClick: () => navigate("/on-demand-app-development") },
                { label: "Architecture as a Service", onClick: () => navigate("/architecture-service") },
                { label: "Startup App Development", onClick: () => navigate("/startup-app-development") },
                { label: "Enterprise Software Development", onClick: () => navigate("/enterprise-software-development") },
            ],
        },

    ];

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    const handleClick = (e) => {
        if (location.pathname === "/") {
            e.preventDefault();
            window.location.reload();
        }
    };
    return (
        <>
            <div className={`sticky top-0 w-full z-10 py-2 header ${
                isScrolled ? 'bg-white shadow-lg' : 'bg-transparent'
            }`}>
                <div className=" flex items-center justify-between px-4 sm:px-6 lg:px-8">

                    <Link to="/" onClick={handleClick}>
                        <img
                            className="h-[60px] w-[140px] md:h-[75px] md:w-[180px]"
                            src={isDarkMode ? "/images2/weblogowhite.svg" : "/images2/Grouplogo.svg"}
                            alt="Bitfront Logo"
                        />
                    </Link>

                    <div className="lg:hidden">
                        <button onClick={toggleMenu}>
                            {menuOpen ? (
                                <FaTimes size={24} className="text-[#0652dd]" />
                            ) : (
                                <FaBars size={24} className="text-[#0652dd]" />
                            )}
                        </button>
                    </div>

                    {/*  Full Menu (shown on large screens)  */}
                    <div className="hidden lg:flex gap-8 lg:ml-5 items-center">
                        {menuItems.map((menuItem, index) => (
                            <div key={index} className="float-left overflow-hidden dropdown">
                                <button
                                    className="dropbtn flex items-center text-base border-0 cursor-pointer py-[25px] bg-inherit m-0 gap-[0.3rem] font-medium leading-[19px] uppercase text-[#000000]">
                                    {menuItem.title}
                                    <FaAngleDown />
                                </button>
                                <div className={`dropdown-content1 hidden absolute bg-white w-max h-auto rounded-[8px] p-[25px] z-10 ${menuItem.itemsRight ? 'min-w-[600px] grid grid-cols-2' : 'min-w-40'}`}>
                                    <div className="w-full">
                                        {menuItem.items.map((item, itemIndex) => (
                                            <button
                                                key={itemIndex}
                                                className="flex w-max items-center gap-2.5 cursor-pointer float-none text-sm text-[#808080] py-[0.25rem] no-underline text-left leading-[22px] hover:text-[#0652dd] hover:translate-x-2 transition-transform duration-300 ease-in-out"
                                                onClick={item.onClick}
                                            >
                                                <FaChevronRight size={12} /> {item.label}
                                            </button>
                                        ))}
                                    </div>
                                    <div className="w-full">
                                        {menuItem.itemsRight && menuItem.itemsRight.map((item, itemIndex) => (
                                            <button
                                                key={itemIndex}
                                                className="flex w-max items-center gap-2.5 cursor-pointer float-none text-sm text-[#808080] py-[0.25rem] no-underline text-left leading-[22px] hover:text-[#0652dd] hover:translate-x-2 transition-transform duration-300 ease-in-out"
                                                onClick={item.onClick}
                                            >
                                                <FaChevronRight /> {item.label}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="flex gap-4">
                            {isDarkMode ? (
                                <button
                                    onClick={handleClickiop}
                                    className="h-11 w-[150px] rounded-full p-0 font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-border text-transparent"
                                >
                                    <div className="bg-[#000] flex items-center justify-center gap-1 h-10 w-[145px] rounded-full text-[15px] p-2 m-auto text-white">
                                       <img src={buttonlogo} className="w-[22px] h-[24px]" alt="buttonlogo" /> BITFRONT AI
                                    </div>
                                </button>
                            ) : (
                                <button
                                    onClick={handleClickiop}
                                    className="h-11 w-[150px] rounded-full flex items-center justify-center gap-1 font-medium border-2 border-[#00008B] cursor-pointer transition duration-100 text-base px-2 hover:text-[#0652dd] text-[#000000]"
                                >
                                   <img src={buttonlogo} className="w-[22px] h-[24px]" alt="buttonlogo" /> BITFRONT AI
                                </button>
                            )}
                            <button
                                onClick={() => navigate("/contact-us")}
                                className="px-4 py-2 leading-5 h-10 w-36 rounded-full font-medium xl:text-[16px] lg:text-[15px] md:text-[14px] text-[13px] bg-[#0652dd] text-white border border-blue-800 transition duration-100 hover:bg-white hover:text-[#0652dd] uppercase"
                            >
                                <b>CONTACT US</b>
                            </button>

                        </div>
                    </div>

                    {/*  Mobile Menu (shown when hamburger is clicked)  */}
                    <div
                        className={`${menuOpen ? "block" : "hidden"
                            } lg:hidden absolute top-full left-0 w-full bg-white border-t border-gray-200`}
                    >
                        {menuItems.map((menuItem, index) => (
                            <div key={index} className="py-2 dropdown px-4 border-b border-gray-200">
                                <button
                                    className="dropbtn flex flex-wrap items-center text-base border-0 cursor-pointer text-[#4c5a67] py-[25px] bg-inherit m-0 gap-[0.3rem] font-medium leading-[19px] uppercase w-full md:w-auto"
                                >
                                    {menuItem.title}
                                    <FaAngleDown className="ml-2" />
                                </button>
                                <div className={`dropdown-content1 hidden absolute bg-white w-full md:w-[600px] h-auto rounded-[8px] p-[25px] z-10 ${menuItem.itemsRight ? 'grid grid-cols-2' : ''}`}>
                                    <div className="w-full">
                                        {menuItem.items.map((item, itemIndex) => (
                                            <button
                                                key={itemIndex}
                                                className="flex w-full md:w-auto items-center gap-2.5 cursor-pointer text-sm text-[#000000] py-[0.25rem] no-underline text-left leading-[22px] hover:text-[#0652dd] hover:translate-x-2 transition-transform duration-300 ease-in-out"
                                                onClick={item.onClick}
                                            >
                                                <FaChevronRight size={12} /> {item.label}
                                            </button>
                                        ))}
                                    </div>
                                    <div className="w-full">
                                        {menuItem.itemsRight && menuItem.itemsRight.map((item, itemIndex) => (
                                            <button
                                                key={itemIndex}
                                                className="flex w-full md:w-auto items-center gap-2.5 cursor-pointer text-sm text-[#000000] py-[0.25rem] no-underline text-left leading-[22px] hover:text-[#0652dd] hover:translate-x-2 transition-transform duration-300 ease-in-out"
                                                onClick={item.onClick}
                                            >
                                                <FaChevronRight /> {item.label}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="flex flex-col gap-4 py-4 px-4">
                            {isDarkMode ? (
                                <button
                                    onClick={handleClickiop}
                                    className="xl:h-11 w-full xl:w-[150px] h-auto rounded-full p-1 font-medium bg-gradient-to-r from-[#08dff8] to-[#b811e5] bg-clip-border text-transparent"
                                >
                                    <div className="bg-[#000] h-10 w-full xl:w-[145px] flex items-center justify-center gap-1 rounded-full text-[17px] p-2 m-auto text-white">
                                    <img src={buttonlogo} className="w-[22px] h-[24px]" alt="buttonlogo" /> BITFRONT AI
                                    </div>
                                </button>
                            ) : (
                                <button
                                    onClick={handleClickiop}
                                    className="h-11 w-full xl:w-[150px] rounded-full flex items-center justify-center gap-1 font-medium border-2 border-[#00008B] cursor-pointer transition duration-100 text-base px-2 hover:text-[#0652dd] text-[#000000]"
                                >
                                    <img src={buttonlogo} className="w-[22px] h-[24px]" alt="buttonlogo" /> BITFRONT AI
                                </button>
                            )}
                            <button
                                onClick={() => navigate("/contact-us")}
                                className="w-full py-2.5 leading-5 text-[15px] bg-[#0652dd] text-white rounded-full border border-blue-800 transition duration-100 hover:bg-white hover:text-[#0652dd] uppercase"
                            >
                                <b>CONTACT US</b>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}