import React, { useState, useEffect } from 'react';
import logo from '.././assets/images/logo.png';
const years = [2020, 2021, 2022, 2023, 2024, 2025];

function Timeline() {
  const [currentYearIndex, setCurrentYearIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentYearIndex((prevIndex) =>
        prevIndex === years.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleYearClick = (index) => {
    setCurrentYearIndex(index);
  };

  return (
    <div className="flex justify-center items-center mt-10">
      <div className="relative flex items-center w-full max-w-3xl">
        <div className="absolute w-full h-1 bg-gray-300 top-1/2 transform -translate-y-1/2"></div>
        {years.map((year, index) => (
          <div
            key={year}
            className="relative flex flex-col items-center cursor-pointer"
            onClick={() => handleYearClick(index)}
            style={{ flex: 1 }}
          >
            <span
              className={`text-gray-500 ${currentYearIndex === index ? 'font-bold text-[#151B40]' : ''
                }`}
            >
              {year}
            </span>
            <div
              className={`w-3 h-3 rounded-full bg-gray-400 mt-2 ${currentYearIndex === index ? 'bg-blue-700' : ''
                }`}
            ></div>
            {currentYearIndex === index && (
              <div className="absolute -bottom-6 rounded-full bg-[#151B40] w-11 h-11 flex items-center justify-center text-white font-bold text-xl">
                <img alt='logo' src={logo} className='w-5 h-6' />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Timeline;
