import React, { useState, useRef, useEffect } from 'react';
import './Chatbot.css';

const Chatbot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const messagesEndRef = useRef(null);

    const toggleChat = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            const initialMessages = [
                { text: "Hi! I'm BIT Chatbot's AI assistant, how can I help you?", sender: 'bot', time: new Date().toLocaleTimeString() },
            ];
            setMessages(initialMessages);
        }
    };

    const handleSendMessage = () => {
        if (input.trim() !== '') {
            const newMessage = { text: input, sender: 'user', time: new Date().toLocaleTimeString() };
            setMessages((prevMessages) => [...prevMessages, newMessage]);

            let botResponse;

            const greetings = ['hi', 'hii', 'hello', 'hy'];
            if (greetings.includes(input.toLowerCase())) {
                botResponse = {
                    text: "Hello! Welcome to BitFront. How can I assist you today with information about our service?",
                    sender: 'bot',
                    time: new Date().toLocaleTimeString(),
                };
            } else if (input.toLowerCase() === 'i need job') {
                botResponse = {
                    text: "You can contact us for job opportunities by calling this number: +91 88662 22169 or sending an email to info@bitfrontinfotech.com.",
                    sender: 'bot',
                    time: new Date().toLocaleTimeString(),
                };
            } else if (input.toLowerCase() === 'how to contact hr') {
                botResponse = {
                    text: "You can contact our HR department for job opportunities or inquiries by calling +91 88662 22169 or sending an email to info@bitfrontinfotech.com. We'll be happy to assist you!",
                    sender: 'bot',
                    time: new Date().toLocaleTimeString(),
                };
            }
            else {
                botResponse = {
                    text: "Hi. I'm here to help! How can I assist you?",
                    sender: 'bot',
                    time: new Date().toLocaleTimeString(),
                };
            }

            setTimeout(() => {
                setMessages((prevMessages) => [...prevMessages, botResponse]);
            }, 2000);

            setInput('');
        }
    };


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSendMessage();
        }
    };

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    return (
        <div className="fixed bottom-5 left-5 z-50">
            {!isOpen ? (
                <button
                    onClick={toggleChat}
                    className="bg-blue-500 bounce-animation text-white px-4 py-4 rounded-full shadow-lg focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" height="32px" width="32px" role="img" className="tawk-min-chat-icon">
                        <path fill="white" fillRule="evenodd" clipRule="evenodd" d="M400 26.2c-193.3 0-350 156.7-350 350 0 136.2
                        77.9 254.3 191.5 312.1 15.4 8.1 31.4 15.1 48.1 20.8l-16.5 63.5c-2 7.8 5.4 14.7 13 12.1l229.8-77.6c14.6-5.3
                        28.8-11.6 42.4-18.7C672 630.6 750 512.5 750 376.2c0-193.3-156.7-350-350-350zm211.1 510.7c-10.8 26.5-41.9
                        77.2-121.5 77.2-79.9 0-110.9-51-121.6-77.4-2.8-6.8 5-13.4 13.8-11.8 76.2 13.7 147.7 13 215.3.3 8.9-1.8
                        16.8 4.8 14 11.7z" />
                    </svg>
                </button>
            ) : (
                <div className="bg-white min-w-max h-[500px] shadow-lg rounded-lg flex flex-col">
                    <div className="bg-blue-500 text-white p-3 rounded-t-lg flex justify-between items-center">
                        <h4 className="font-bold">Chat With Us Now</h4>
                        <button
                            onClick={toggleChat}
                            className="text-white focus:outline-none text-lg">
                            ✕
                        </button>
                    </div>

                    <div className="flex-1 p-3 overflow-y-auto bg-gray-100">
                        {messages.map((msg, index) => (
                            <div key={index} className={`my-2 flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
                                {msg.sender === 'bot' && (
                                    <img
                                        src="images2/chatbot.png"  // Replace with the actual path to the bot's profile image
                                        alt="Bot"
                                        className="w-8 h-8 rounded-full mr-2"
                                    />
                                )}
                                <div className={`inline-block p-2 rounded-lg max-w-xs ${msg.sender === 'user' ? 'bg-blue-200' : 'bg-white border'}`}>
                                    <p>{msg.text}</p>
                                    <span className="text-xs text-gray-500">{msg.time}</span>
                                </div>
                                {msg.sender === 'user' && (
                                    <img
                                        src="images2/chatuser.png"  // Replace with the actual path to the user's profile image
                                        alt="User"
                                        className="w-8 h-8 rounded-full ml-2"
                                    />
                                )}
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>

                    <div className="p-3 bg-white border-t">
                        <div className="flex">
                            <input
                                type="text"
                                placeholder="Type a message..."
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                onKeyDown={handleKeyDown}
                                className="flex-1 px-3 py-2 border rounded-lg focus:outline-none"
                            />
                            <button
                                onClick={handleSendMessage}
                                className="ml-2 bg-blue-500 text-white px-4 py-2 rounded-lg">
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chatbot;
