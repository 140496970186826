import React from 'react';
import Header from '../../components/layout/Header'
import { FaStar } from "react-icons/fa6";
import { FaArrowCircleRight } from "react-icons/fa";
import Footer from "../Footer";
import uiuxdesign from '../../assets/images/uiuxdesign.png';
import MobileAppDesign from '../../assets/images/MobileAppDesign.png';
import WebAppDesign from '../../assets/images/WebAppDesign.png';
import WebsiteUIUXDesign from '../../assets/images/WebsiteUIUXDesign.png';
import Group from '../../assets/images/Group.png';
import bluesc from '../../assets/images/bluesc.png';
import bluebox from '../../assets/images/bluebox.png';
import bluefq from '../../assets/images/bluefq.png';
import hpdc from '../../assets/images/hpdc.png';
import figmas from '../../assets/images/figmas.png';
import photoshop from '../../assets/images/photoshop.png';
import mockplus from '../../assets/images/mockplus.png';
import sketch from '../../assets/images/sketch.png';
import invision from '../../assets/images/invision.png';
import affinitydesigner from '../../assets/images/affinitydesigner.png';
import marvelapps from '../../assets/images/marvelapps.png';
import picmonkeys from '../../assets/images/picmonkeys.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const UIUXdesign = () => {
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">UI/UX</span> Design Company</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            We specialize in comprehensive UI/UX design services, crafting compelling and immersive user experiences that leave a lasting impact across every interaction with your brand.
                        </p>
                        <ul className="list-none text-[16px] md:text-[18px] pt-[10px]">
                            <li className="mt-2">Technically feasible design</li>
                            <li className="mt-2">Design focused on Android and iOS guidelines</li>
                            <li className="mt-2">Analytics dashboard backend design</li>
                            <li className="mt-2">Clickable prototype with UI kit</li>
                        </ul>
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[200px] mt-10 h-[40px]">
                            TALK TO OUR EXPERT
                        </button>
                    </div>
                    <img alt='uiuxdesign' className="w-full h-auto object-cover" src={uiuxdesign} />
                </div>
            </div>

            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-[150px] mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px] font-semibold "><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                </div>
            </div>


            <div className="flex flex-wrap justify-center mt-14 ">
                <div className="w-[575px] h-[212px] border-[0px] bg-[#e1e1e1]">
                    <h1 className="text-[18px] font-bold p-[50px] ">
                        We specialize in comprehensive UI/UX design services, crafting
                        compelling and immersive user experiences that leave a lasting
                        impact across every interaction with your brand.
                    </h1>
                </div>
                <div className="w-[575px] h-[413px] text-white p-[50px] bg-[#2e6de0]">
                    <li className="text-[18px] pt-[10px]">Technically feasible design</li>
                    <li className="text-[18px] pt-[10px]">Design focused on Android and iOS guidelines</li>
                    <li className="text-[18px] pt-[10px]">Analytics dashboard backend design</li>
                    <li className="text-[18px] pt-[10px]">Mind map</li>
                    <li className="text-[18px] pt-[10px]">Wireframes</li>
                    <li className="text-[18px] pt-[10px]">Motion Design</li>
                    <li className="text-[18px] pt-[10px]">Figma design</li>
                    <li className="text-[18px] pt-[10px]">Clickable prototype with UI kit</li>
                </div>
            </div>

            <h1 className="text-[#2E6DE0] font-bold text-[32px] lg:w-[550px] w-0 m-auto text-center mt-9">UI/UX Design Services
                <span className="text-black"> Inspiring User Action</span></h1>
            <div className="flex flex-wrap justify-center items-center mt-12 gap-10">
                <div className="p-[30px] bg-white max-w-md w-full">
                    <div className='border-[1px] p-7'>
                        <h3 className="text-[25px] font-bold">Mobile App Design</h3>
                        <p className="text-[19px] mt-4">
                            At BitFront, we hold expertise in creating user interface designs for mobile apps. No matter what your brand touchpoint is, we ensure that the experience users will get is similar all across.
                        </p>
                    </div>
                    <div className="mt-10 flex gap-5 items-start">
                        <img alt="bluesc" className="w-[90px] h-[90px]" src={bluesc} />
                        <div className="p-[30px] bg-[#f7db7c]">
                            <ul className="list-none space-y-2">
                                <li className="text-[18px] font-bold">Market Research</li>
                                <li className="text-[18px] font-bold">Wireframe Creation</li>
                                <li className="text-[18px] font-bold">Sketching and Wireframing</li>
                                <li className="text-[18px] font-bold">Quality Assurance (QA)</li>
                                <li className="text-[18px] font-bold">Prototyping</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <img alt="Mobile App Design" className="max-w-full h-auto md:w-[551px]" src={MobileAppDesign} />
            </div>

            <div className="flex flex-wrap items-center justify-center gap-8">
                <img alt="Web App Design" className="max-w-full h-auto md:w-[539px] md:h-[366px]" src={WebAppDesign} />
                <div className="p-[30px] bg-white max-w-md w-full">
                    <div className='border-[1px] p-7'>
                        <h3 className="text-[25px] font-bold">Web App Design</h3>
                        <p className="text-[19px] mt-4">
                            The team at BitFront excels in crafting user interface designs for diverse platforms, including web applications.
                            Regardless of your brand touchpoint, we guarantee a consistent user experience across all platforms.
                        </p>
                    </div>
                    <div className="mt-10 flex justify-center items-start">
                        <img alt="bluebox" className="w-[90px] h-[90px] mr-4" src={bluebox} />
                        <div className="p-[20px] bg-[#ef8168] rounded">
                            <ul className="list-none space-y-2">
                                <li className="text-[18px] font-bold">Market Research</li>
                                <li className="text-[18px] font-bold">Wireframe Creation</li>
                                <li className="text-[18px] font-bold">Information Architecture</li>
                                <li className="text-[18px] font-bold">Prototype Creation</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-wrap justify-center items-center gap-9">
                <div className="p-[40px] bg-white w-full max-w-md h-auto">
                    <div className='border-[1px] p-7'>
                        <h3 className="text-[25px] font-bold">Website UI/UX Design</h3>
                        <p className="text-[19px] mt-4">
                            We carry a skillset in building organic UI/UX that moves users from one screen to another almost intuitively.
                            From smooth transitions to clarity of the next steps, our UX design services make your users feel at home
                            inside your website.
                        </p>
                    </div>
                    <div className="mt-10 flex items-start">
                        <img alt="bluefq" className="w-[90px] h-[90px] mr-4" src={bluefq} />
                        <div className="p-[20px] bg-[#a5cca6] rounded">
                            <ul className="list-none space-y-2">
                                <li className="text-[18px] font-bold">Market Research</li>
                                <li className="text-[18px] font-bold">Interaction Design</li>
                                <li className="text-[18px] font-bold">Information Architecture</li>
                                <li className="text-[18px] font-bold">Prototyping</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <img alt="Website UI/UX Design" className="max-w-full h-auto md:w-[539px] md:h-[386px]" src={WebsiteUIUXDesign} />
            </div>

            <div className="flex flex-wrap items-center justify-center gap-8">
                <img alt="Group" className="w-full max-w-[689px] xl:h-[500px] h-auto" src={Group} />
                <div className="p-[50px] border-gray-300 bg-white w-full max-w-[551px] h-auto">
                    <div className='border-[1px] p-7'>
                        <h3 className="text-[25px] font-bold">Seamless Digital Product Design for Your Brand</h3>
                        <p className="text-[19px] mt-4">
                            At BitFront, we excel in crafting user interface designs for diverse platforms, including mobile, web, and
                            wearables. Make the product stand out through custom style, fonts, icons, and animations.
                        </p>
                    </div>
                    <div className="mt-[40px] flex items-start gap-4">
                        <div className="p-[20px] bg-[#c8ac90] rounded">
                            <ul className="list-none space-y-2">
                                <li className="text-[18px] font-bold">Frontend Development</li>
                                <li className="text-[18px] font-bold">Information Architecture</li>
                                <li className="text-[18px] font-bold">Prototype Creation</li>
                                <li className="text-[18px] font-bold">Multiple Engagement Models</li>
                            </ul>
                        </div>
                        <img alt="hpdc" className="w-[90px] h-[90px]" src={hpdc} />
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-center mt-[70px]">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-4xl w-full">
                    <h1 className="text-3xl font-bold text-center mb-4">
                        Effective <span className="text-blue-600">UI/UX Design</span> Methodology
                    </h1>
                    <p className="text-center mb-6">
                        At BitFront, we excel in crafting user interface designs for diverse platforms, including mobile, web, and wearables.
                        Regardless of your brand touchpoint, we guarantee a consistent user experience across all platforms.
                    </p>
                    <div className="relative flex flex-col items-center">
                        <div className="absolute inset-0 flex items-center justify-center">
                            <div className="w-full border-dashed border-t-2 border-gray-300"></div>
                        </div>
                        <ul className="flex flex-wrap justify-between w-full mb-4 list-none">
                            {["Reviewing Design", "Mind Mapping", "Wireframing", "Prototyping", "UI Design", "UX Design", "Design System"].map((step, index) => (
                                <li key={index} className="flex flex-col items-center">
                                    <div className="bg-white border-2 border-gray-300 rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold">
                                        {index + 1 < 10 ? `0${index + 1}` : index + 1}
                                    </div>
                                    <div className="mt-2 text-center">{step}</div>
                                </li>
                            ))}
                        </ul>
                        <div className="mt-6 text-center">
                            <h2 className="text-xl font-bold text-blue-600">Reviewing Design or Defining Scope</h2>
                            <p className="mt-2">
                                We examine your existing app design or vision to develop a project scope that outlines the requirements and
                                deliverables.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <h1 className="xl:text-[38px] lg:text-[30px] md:text-[20px] text-[21px] font-bold text-center mt-10">The
                <span className="text-[#0652dd]"> Technological Platforms</span> We Depend On</h1>
            <p className="text-center text-[18px]">
                We create your digital products on platforms designed with a focus on creativity and intuitiveness in their architecture.
            </p>
            <div className="flex flex-wrap justify-center gap-[40px] mt-10">
                <div className="text-center">
                    <img alt='figmas' src={figmas} />
                    <p>Figma</p>
                </div>
                <div className="text-center">
                    <img alt='photoshop' src={photoshop} />
                    <p>Photoshop</p>
                </div>
                <div className="text-center">
                    <img alt='mockplus' src={mockplus} />
                    <p>Mockplus</p>
                </div>
                <div className="text-center">
                    <img alt='sketch' src={sketch} />
                    <p>Sketch</p>
                </div>
                <div className="text-center">
                    <img alt='invision' src={invision} />
                    <p>Invision</p>
                </div>
                <div className="text-center">
                    <img alt='affinitydesigner' src={affinitydesigner} />
                    <p>Affinity Designer</p>
                </div>
                <div className="text-center">
                    <img alt='marvelapps' src={marvelapps} />
                    <p>Marvel Apps</p>
                </div>
                <div className="text-center">
                    <img alt='picmonkeys' src={picmonkeys} />
                    <p>PicMonkey</p>
                </div>
            </div>


            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[24px] text-[21px] text-center font-bold mt-[70px]">Looking for the best Mobile App Development Services for your<br />
                business?</h1>
            <p className="text-[18px] text-center">We help top brands deliver quality products. Share your new business needs, and let us make it a success.</p>

            <div className="container mx-auto px-6 py-[30px]">
                <h2 className="lg:text-[40px] text-[30px] font-bold lg:leading-[59px] leading-[44px] text-center text-[#192734]">Helping Create <span className="text-[#0652dd]">Tomorrow’s Brands</span></h2>
                <p className="text-center text-base leading-[30px] text-[#4c5a67] font-normal">We are the mobile app development company that shapes success stories.</p>
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                    <div className="w-[344px] hidden md:block absolute top-[83px] right-[134px]">
                        <p className="text-lg">
                            Re-building Banksathi - A finance app making it easy for financial experts to make money.
                        </p>
                        <div className="inline-row">
                            <div>
                                <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">
                                    4.4
                                    <p className="text-[#DE5B48] text-[10px] flex gap-1">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </p>
                                </span>
                                <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
                            </div>
                            <div>
                                <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
                                <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
                            </div>
                        </div>
                        <h1 className="my-3">1 Million+ downloads</h1>
                        <div className="flex gap-4">
                            <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
                                <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" />
                                <p><b>View Case Study</b></p>
                            </button>
                            <img className="w-[124px] h-[36px] cursor-pointer" alt="img" src="/images2/41p.jpg" />
                        </div>
                    </div>
                    <div>
                        <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
                        <p className="my-2 text-base leading-[30px] text-[#4c5a67] font-normal">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
                            in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
                            scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
                    </div>
                    <div className="flex items-center gap-4">
                        <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
                        <div>
                            <p>Corey Smith </p>
                            <p>CEO, Emerge</p>
                        </div>
                    </div>
                </div>
                <div className="justify-center flex pt-5">
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default UIUXdesign
